import { actions } from '../actions/get-support'
import { getters } from '../getters/get-support'
import { mutations } from '../mutations/get-support'

export const getSupport = {
  namespaced: true,
  state: {
    getSupport: {
      response: null,
      error: null,
    },
  },
  actions,
  getters,
  mutations,
}
