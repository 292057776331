import { actions } from '../actions/plp'
import { getters } from '../getters/plp'
import { mutations } from '../mutations/plp'

export const plp = {
  namespaced: true,
  state: {
    attributes: {},
    facets: {},
    facetsCount: {},
    facetsSelected: { idsFiltersCheckedList: [], extraFacets: [] },
    facetsGroupNames: {},
    filteredCMSBanners: '',
    isFacetsFirstLoad: false,
    isFilterCategoryLabel: true,
    isFilterCategoryHidden: {},
    nextPageURL: '',
    pagination: {},
    products: [],
    ui: {
      body: {},
      plpLoadingProducts: false,
      plpLoadingMoreProducts: false,
      plpLoadingFacets: false,
      sidebar: false,
      filterItemClicked: '',
      mobileView: '',
    },
    firstNewProductsIndex: -1,
  },
  actions,
  getters,
  mutations,
}
