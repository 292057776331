export const UPDATE_GIFTCARD_BALANCE = 'UPDATE_GIFTCARD_BALANCE'
export const UPDATE_GIFTCARD_BALANCE_ERROR = 'UPDATE_GIFTCARD_BALANCE_ERROR'

export const mutations = {
  [UPDATE_GIFTCARD_BALANCE]: function(state, payload) {
    state.getSupport.response = payload
  },

  [UPDATE_GIFTCARD_BALANCE_ERROR]: function(state, payload) {
    state.getSupport.error = payload
  },
}
