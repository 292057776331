export const getters = {
  myAccountDetails: state => state.myAccountDetails || {},
  profileDetails: state => state.profileDetails || [],
  recoveryDetails: state => state.recoveryDetails || {},
  passwordRequirements: state => state.passwordRequirements || {},
  orders: state => state.orders || {},
  favouriteStores: state => state.favouriteStores || [],
  appointmentsBooking: state => state.appointmentsBooking || {},
  loyaltyProducts: state => state.loyaltyProducts || [],
  loyaltyProductSelected: state => state.loyaltyProductSelected || null,
  loyaltyScriptLoaded: state => state.loyaltyScriptLoaded || false,
  breadcrumb: state => state.breadcrumb || [],
  cartState: state => state.cartState || [],
}
