export const UPDATE_FA_CURRENTPAGE = 'UPDATE_FA_CURRENTPAGE'
export const UPDATE_FA_LANDING = 'UPDATE_FA_LANDING'
export const UPDATE_FA_RESET_PLP = 'UPDATE_FA_RESET_PLP'
export const UPDATE_FA_SAVE_UPCS = 'UPDATE_FA_SAVE_UPCS'
export const UPDATE_FA_USER_PROFILE_DATA = 'UPDATE_FA_USER_PROFILE_DATA'
export const UPDATE_LOAD_FA_PLP_PRODUCTS = 'UPDATE_LOAD_FA_PLP_PRODUCTS'
export const UPDATE_PDP_VISITED_URL = 'UPDATE_PDP_VISITED_URL'

export const mutations = {
  //save all products UPC returned from FA api
  [UPDATE_FA_SAVE_UPCS]: function(state, payload) {
    state.fa.UPCS = [...payload]
  },
  //reset plp:
  [UPDATE_FA_RESET_PLP]: function(state) {
    state.fa.products = []
    state.fa.usedUPCS = []
  },
  //save all products retrieved from BE api and update list of usedUPCS
  [UPDATE_LOAD_FA_PLP_PRODUCTS]: function(state, payload) {
    const { data, UPCSToFetch } = payload
    state.fa.products = [...state.fa.products, ...data.products]
    state.fa.usedUPCS = [...state.fa.usedUPCS, ...UPCSToFetch]
  },
  //update current page
  [UPDATE_FA_CURRENTPAGE]: function(state, page) {
    //if currentPage is present in session update state, otherwise increment
    if (page) {
      state.fa.currentPage = page
    } else {
      state.fa.currentPage++
    }
    //save or update in sessionStorage currentPage
    if (sessionStorage.SGHVueFA) {
      var SGHVueFA = JSON.parse(sessionStorage.SGHVueFA)
    } else {
      var SGHVueFA = {}
    }
    SGHVueFA.currentPage = state.fa.currentPage
    sessionStorage.setItem('SGHVueFA', JSON.stringify(SGHVueFA))
  },
  //update visitedPdpUrl
  [UPDATE_PDP_VISITED_URL]: function(state, payload) {
    state.fa.visitedPdpUrl = payload ? payload : ''
  },
  //hide landing and show FA component and PLP
  [UPDATE_FA_LANDING]: function(state, payload) {
    state.fa.showLanding = payload
    //save or update in sessionStorage showLanding status
    if (sessionStorage.SGHVueFA) {
      var SGHVueFA = JSON.parse(sessionStorage.SGHVueFA)
    } else {
      var SGHVueFA = {}
    }
    SGHVueFA.showLanding = payload
    sessionStorage.setItem('SGHVueFA', JSON.stringify(SGHVueFA))
  },

  // Update FA user data
  [UPDATE_FA_USER_PROFILE_DATA]: function(state, payload) {
    state.fa.userProfileData = payload
  },
}
