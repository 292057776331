import makeDebug from 'debug'
import qs from 'qs'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import sortBy from 'lodash/sortBy'
import union from 'lodash/union'
import remove from 'lodash/remove'
import { updateUrl } from '../../libs/utils/url'
import { getSAHingeFilterId } from '../../libs/utils/const'

const debug = makeDebug('store:mutation')
const hingeFilterIdSA = getSAHingeFilterId()

export const UPDATE_LOAD_PLP_PRODUCTS = 'UPDATE_LOAD_PLP_PRODUCTS'
export const UPDATE_LOAD_PLP_FACETS = 'UPDATE_LOAD_PLP_FACETS'
export const UPDATE_UI = 'UPDATE_UI'
export const UPDATE_UI_BODY = 'UPDATE_UI_BODY'
export const UPDATE_FACETS = 'UPDATE_FACETS'
export const UPDATE_RESET_PAGINATION_DATA = 'UPDATE_RESET_PAGINATION_DATA'
export const UPDATE_NEXT_PAGE_URL = 'UPDATE_NEXT_PAGE_URL'
export const UPDATE_URL = 'UPDATE_URL'
export const UPDATE_IS_FACETS_FIRST_LOAD = 'UPDATE_IS_FACETS_FIRST_LOAD'
export const UPDATE_FACETS_COUNT = 'UPDATE_FACETS_COUNT'
export const UPDATE_CLONE_FILTER_OPTIONS_LIST = 'UPDATE_CLONE_FILTER_OPTIONS_LIST'
export const UPDATE_FILTER_CATEGORY_LABEL = 'UPDATE_FILTER_CATEGORY_LABEL'
export const UPDATE_IDS_FILTERS_CHECKED = 'UPDATE_IDS_FILTERS_CHECKED'
export const UPDATE_REMOVE_FACET_COUNT = 'UPDATE_REMOVE_FACET_COUNT'
export const UPDATE_EXTRAFACETS = 'UPDATE_EXTRAFACETS'
export const UPDATE_FILTERED_CMS_BANNERS = 'UPDATE_FILTERED_CMS_BANNERS'
export const UPDATE_LOAD_PLP_PRODUCTS_VARIANTS = 'UPDATE_LOAD_PLP_PRODUCTS_VARIANTS'
export const UPDATE_RETRIEVE_FAVORITE_LIST = 'UPDATE_RETRIEVE_FAVORITE_LIST'
export const UPDATE_ADD_REMOVE_FAVORITE = 'UPDATE_ADD_REMOVE_FAVORITE'

export const mutations = {
  [UPDATE_LOAD_PLP_PRODUCTS]: function(state, payload) {
    debug('%s payload %o', UPDATE_LOAD_PLP_PRODUCTS, payload)
    const { attributes, products, override, ...pagination } = payload
    state.firstNewProductsIndex = override ? -1 : state.products.length
    state.products = override ? [...products] : [...state.products, ...products]
    state.attributes = Object.assign({}, state.attributes, attributes)
    state.pagination = Object.assign({}, state.pagination, pagination)
  },
  //used with algolia to delay ungrouped call
  [UPDATE_LOAD_PLP_PRODUCTS_VARIANTS]: function(state, payload) {
    const { products } = payload
    state.products = [...products]
  },
  [UPDATE_LOAD_PLP_FACETS]: function(state, payload) {
    debug('%s payload %o', UPDATE_LOAD_PLP_FACETS, payload)
    state.facets = Object.assign({}, state.facets, { ...payload })
  },
  [UPDATE_UI]: function(state, payload) {
    if (find(state.facets.filterOptionsList, { groupName: 'brand' })) {
      const brandList = find(state.facets.filterOptionsList, { groupName: 'brand' }).list
      if (!isEmpty(brandList)) {
        find(state.facets.filterOptionsList, { groupName: 'brand' }).list = sortBy(
          brandList,
          'facetIdentifier'
        )
      }
    }
    debug('%s payload %o', UPDATE_UI, payload)
    state.ui = Object.assign({}, state.ui, { ...payload })
  },
  [UPDATE_FACETS]: function(state, payload) {
    state.facetsSelected = Object.assign({}, state.facetsSelected, { ...payload })
  },
  //#SGHDP-9070 reset pagination data from endpoint to previous values
  [UPDATE_RESET_PAGINATION_DATA]: function(state, payload) {
    const { ...pagination } = payload
    if (parseInt(state.facetsSelected.currentPage) > 1) {
      pagination.currentPage = parseInt(state.facetsSelected.currentPage)
      pagination.pageSize = parseInt(pagination.pageSize / pagination.currentPage)
      pagination.totalPages = Math.ceil(pagination.totalProducts / pagination.pageSize)
      state.pagination = { ...state.pagination, ...pagination }
    }
  },
  //TODO all the "next page url" flow (action and mutation) is pointless, state.nextPageURL is never used, can be removed entirely
  [UPDATE_NEXT_PAGE_URL]: function(state, payload) {
    state.nextPageURL = payload.nextPageURL
  },
  [UPDATE_URL]: function(state, { idsFiltersCheckedList, ...rest }) {
    //TODO is this still needed? currentParams gets overwritten anyway
    const currentParams = qs.parse(decodeURIComponent(window.location.search), {
      ignoreQueryPrefix: true,
    })

    if (state.facetsSelected.extraFacets)
      idsFiltersCheckedList = union([...state.facetsSelected.extraFacets, ...idsFiltersCheckedList])
    const { currentPage, orderBy, clearUrl } = rest

    //TODO we shouldn't reset state in an update url function, a dedicated mutation should do it
    if (clearUrl) {
      idsFiltersCheckedList = []
      state.facetsSelected.idsFiltersCheckedList = []
      state.facetsGroupNames = []
      currentParams['currentPage'] = 1
      currentParams['facet'] = []
    } else {
      idsFiltersCheckedList.length > 0
        ? (currentParams['facet'] = idsFiltersCheckedList)
        : (currentParams['facet'] = [])
      if (orderBy && orderBy != 'default') currentParams['orderBy'] = orderBy
      currentPage != 1 && currentPage !== undefined
        ? (currentParams['currentPage'] = currentPage)
        : (currentParams['currentPage'] = 1)
    }

    updateUrl(currentParams)
  },
  [UPDATE_IS_FACETS_FIRST_LOAD]: function(state, payload) {
    state.isFacetsFirstLoad = payload
  },
  [UPDATE_FACETS_COUNT]: function(state, payload) {
    // creates an object with all the facets ids as keys
    // and the filters items count as value, we are using it
    // to check if the filters should be visible on first load
    let outputFacets = {}
    if (payload.facet?.filterOptionsList) {
      payload.facet.filterOptionsList.forEach(function(group) {
        if (group.list) {
          group.list.forEach(function(filter) {
            outputFacets[filter.id] = filter.count
          })
        }
      })
    }
    state.facetsCount = outputFacets
  },
  [UPDATE_UI_BODY]: function(state, payload) {
    const key = Object.keys(payload)
    key.forEach(e => {
      document.body.style[e] = payload[e]
    })
    state.ui.body = Object.assign({}, state.ui.body, payload)
  },
  //TODO check if https://bitbucket.org/luxottica_rona/sgh-static-ui/pull-requests/289 is still needed and if there's a better way to handle this
  //see also https://bitbucket.org/luxottica_rona/sgh-static-ui/pull-requests/?state=MERGED&query=10328
  [UPDATE_CLONE_FILTER_OPTIONS_LIST]: function(state, payload) {
    state.isFilterCategoryHidden = JSON.parse(JSON.stringify(payload))
  },
  // end SGHDP-10243
  [UPDATE_FILTER_CATEGORY_LABEL]: function(state, payload) {
    state.isFilterCategoryLabel = payload
  },
  [UPDATE_IDS_FILTERS_CHECKED]: function(state, payload) {
    const { id, reset } = payload
    if (reset) {
      // state.facetsSelected.idsFiltersCheckedList =  state.facetsSelected.idsFiltersCheckedList.splice(0, state.facetsSelected.idsFiltersCheckedList.length)
      state.facetsSelected.idsFiltersCheckedList = []
      state.facetsGroupNames = []
    } else {
      //if user interacts with a price interval filter in UI, remove custom price filter from extrafacet
      if (id.substr(0, 5) === 'price') {
        remove(state.facetsSelected.extraFacets, facet => {
          return facet.substr(0, 5) === 'price'
        })
      }
      //remove hinge extrafacet when toggled off
      if (id.includes(hingeFilterIdSA)) {
        remove(state.facetsSelected.extraFacets, facet => {
          return facet.includes(hingeFilterIdSA)
        })
      }

      //if there's a click on facets:  add it ot remove from our list of checked filter
      let indexOf = state.facetsSelected.idsFiltersCheckedList.indexOf(id)
      if (indexOf > -1) {
        state.facetsSelected.idsFiltersCheckedList.splice(indexOf, 1)
      } else {
        state.facetsSelected.idsFiltersCheckedList.push(id)
      }

      // add it or remove it for the filters grouped collection (used in group counters) - one id can belong to more groups
      state.facets.filterOptionsList.forEach(el => {
        //check if id is present in groupName options
        if (find(el.list, { id: id })) {
          //if necessary initialize groupName array
          if (state.facetsGroupNames[el.groupName] === undefined) {
            state.facetsGroupNames[el.groupName] = []
          }
          //add or remove clicked facet from group
          let indexOfAlt = state.facetsGroupNames[el.groupName].indexOf(id)
          if (indexOfAlt > -1) {
            state.facetsGroupNames[el.groupName].splice(indexOfAlt, 1)
          } else {
            state.facetsGroupNames[el.groupName].push(id)
          }
        }
      })
    }
  },
  [UPDATE_REMOVE_FACET_COUNT]: function(state, id) {
    // remove a count=0 filter from the object because has
    // become available again
    delete state.facetsCount[id]
  },
  [UPDATE_EXTRAFACETS]: function(state, extraFacets) {
    state.facetsSelected.extraFacets = extraFacets
  },
  [UPDATE_FILTERED_CMS_BANNERS]: function(state, payload) {
    state.filteredCMSBanners = payload
  },

  [UPDATE_RETRIEVE_FAVORITE_LIST]: function(state, payload) {
    const wishlistProducts = payload.wishlistItems.products || []
    wishlistProducts.forEach(wishlistProduct => {
      const product = state.products.find(p => p.catentryId === wishlistProduct.catentryId)
      if (product) {
        product.wishlistRelatedLinks = wishlistProduct.wishlistRelatedLinks
        product.wishlistRelatedLinks['isInWishlist'] = true
      }
    })
  },
  [UPDATE_ADD_REMOVE_FAVORITE]: function(state, payload) {
    const productToUpdate = state.products?.find(e => e.catentryId === payload.catentryId)
    if (productToUpdate) {
      productToUpdate.wishlistRelatedLinks.isInWishlist = payload.isInWishlist
    }
  },
}
