

import { sendVerifyEmail } from '../../libs/wcs/my-account'
import { loadSectionTranslations } from '../../i18n-setup'

export default {
  name: 'sgh-my-account-email-failed',

  data() {
    return {}
  },

  mounted() {
    loadSectionTranslations('my-account-new')
  },

  methods: {
    sendNewVerifyEmail() {
      const param = { resendDataId: this.$route.query.resendDataId }
      sendVerifyEmail(param)
    },
  },
}
