export const UPDATE_SHORTCODE = 'UPDATE_SHORTCODE'
export const UPDATE_SHORTCODE_LOADING = 'UPDATE_SHORTCODE_LOADING'

export const mutations = {
  [UPDATE_SHORTCODE]: function(state, payload) {
    state.shortCode.shortcodeList = payload
  },
  [UPDATE_SHORTCODE_LOADING]: function(state, payload) {
    state.shortCode.loading = payload
  },
}
