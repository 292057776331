
import { getCurrentCountry } from '../../libs/utils/currentCountry'
import { loadSectionTranslations } from '../../i18n-setup'
export default {
  name: 'sgh-my-account-email-confirmed',

  data() {
    return {}
  },

  computed: {
    goToHome() {
      return `/${getCurrentCountry()}`
    },
  },
  mounted() {
    loadSectionTranslations('my-account-new')
  },

  methods: {},
}
