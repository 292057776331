import { actions } from '../actions/shortcode'
import { getters } from '../getters/shortcode'
import { mutations } from '../mutations/shortcode'

export const shortcode = {
  namespaced: true,
  state: {
    shortCode: {
      shortcodeList: null,
      loading: false,
    },
  },
  actions,
  getters,
  mutations,
}
