import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'

export const UPDATE_LOAD_SL = 'UPDATE_LOAD_SL'
export const UPDATE_SL_STORES_FILTERED = 'UPDATE_SL_STORES_FILTERED'
export const UPDATE_SL_FILTERS_CHECKED = 'UPDATE_SL_FILTERS_CHECKED'
export const UPDATE_SHOW_TAB = 'UPDATE_SHOW_TAB'
export const UPDATE_SL_STORES_VISIBLE = 'UPDATE_SL_STORES_VISIBLE'
export const UPDATE_SL_APPLIED_FILTERS = 'UPDATE_SL_APPLIED_FILTERS'
export const UPDATE_SL_EMPTY_FILTERS = 'UPDATE_SL_EMPTY_FILTERS'
export const UPDATE_REVERT_SL_FILTERS_TO_APPLIED = 'UPDATE_REVERT_SL_FILTERS_TO_APPLIED'
export const UPDATE_REVERT_SL_STORES_TO_VISIBLE = 'UPDATE_REVERT_SL_STORES_TO_VISIBLE'
export const UPDATE_SL_SET_ACTIVE_STORE = 'UPDATE_SL_SET_ACTIVE_STORE'
export const UPDATE_SL_REMOVE_STORES_VISIBLE = 'UPDATE_SL_REMOVE_STORES_VISIBLE'

export const mutations = {
  //update store locator state after fetching BE: latitude,longitude,radius,stores,filters
  [UPDATE_LOAD_SL]: function(state, payload) {
    state.sl = { ...state.sl, ...payload }
  },
  //update list of filtered stores when filter change detected
  [UPDATE_SL_STORES_FILTERED]: function(state) {
    //filter store list by active filters
    const filteredStores = state.sl.stores.filter(s => {
      const addStore =
        (isEmpty(s.storeBrands) ||
          state.sl.filtersCheckedList.storeBrands.every(v => {
            return (
              state.sl.filtersCheckedList.storeBrands.length === 0 ||
              s.storeBrands.indexOf(v) !== -1
            )
          })) &&
        (isEmpty(s.storeType) ||
          state.sl.filtersCheckedList.storeType.length === 0 ||
          state.sl.filtersCheckedList.storeType.includes(s.storeType)) &&
        (s.bookingAvailable ||
          state.sl.filtersCheckedList.bookingAvailable.length === 0 ||
          state.sl.filtersCheckedList.bookingAvailable.includes(s.bookingAvailable)) &&
        (s.stellaAvailable ||
          state.sl.filtersCheckedList.stellaAvailable.length === 0 ||
          state.sl.filtersCheckedList.stellaAvailable.includes(s.stellaAvailable)) &&
        (s.rxAvailable ||
          state.sl.filtersCheckedList.rxAvailable.length === 0 ||
          state.sl.filtersCheckedList.rxAvailable.includes(s.rxAvailable))

      return addStore
    })
    for (let key in state.sl.filtersCheckedList)
      if (state.sl.filtersCheckedList[key].length > 0) {
        state.sl.storesFiltered = filteredStores
        return
      }
    state.sl.storesFiltered = state.sl.stores
  },
  //update list of sl filters checked when filters change detected
  [UPDATE_SL_FILTERS_CHECKED]: function(state, payload) {
    const { option, reset, groupName } = payload
    if (reset) {
      //TODO bind to clear button
      state.sl.filtersCheckedList = []
    } else {
      /*
        - add or remove clicked filter from related groupname in filtersCheckedList
      */

      if (state.sl.filtersCheckedList[groupName] === undefined) {
        state.sl.filtersCheckedList[groupName] = []
      }
      let indexOf = state.sl.filtersCheckedList[groupName].indexOf(option)

      if (indexOf > -1) {
        //facet is already selected -> remove id from groupName
        state.sl.filtersCheckedList[groupName].splice(indexOf, 1)
      } else {
        //facet is not already selected -> push id to groupName

        state.sl.filtersCheckedList[groupName].push(option)
      }
    }
  },
  //update sl navigation tab
  [UPDATE_SHOW_TAB]: function(state, tabName) {
    state.sl.showTab = tabName
  },
  //update list of visible stores when filters are actually applied
  [UPDATE_SL_STORES_VISIBLE]: function(state) {
    state.sl.storesVisible = state.sl.storesFiltered
  },
  //update applied filters list for persistence
  [UPDATE_SL_APPLIED_FILTERS]: function(state) {
    state.sl.filtersAppliedList = cloneDeep(state.sl.filtersCheckedList)
  },
  //empty checked filters and reset storesFiltered to all available
  [UPDATE_SL_EMPTY_FILTERS]: function(state) {
    state.sl.filtersCheckedList = {
      storeType: [],
      storeBrands: [],
      bookingAvailable: [],
      rxAvailable: [],
      stellaAvailable: [],
    }
    state.sl.storesFiltered = state.sl.stores
  },
  //reset filters to active when modal is closed without applying filters
  [UPDATE_REVERT_SL_FILTERS_TO_APPLIED]: function(state) {
    state.sl.filtersCheckedList = cloneDeep(state.sl.filtersAppliedList)
  },
  //reset filtered stores to visible when modal is closed without applying filters
  [UPDATE_REVERT_SL_STORES_TO_VISIBLE]: function(state) {
    state.sl.storesFiltered = state.sl.storesVisible
  },
  //update active / selected store highlighted in ui
  [UPDATE_SL_SET_ACTIVE_STORE]: function(state, storeId) {
    let currentActiveStore = state.sl.storesVisible.find(({ active }) => active === true)
    if (!isEmpty(currentActiveStore)) currentActiveStore.active = false
    let nextActiveStore = state.sl.storesVisible.find(({ id }) => id === storeId)
    nextActiveStore.active = true
  },
  [UPDATE_SL_REMOVE_STORES_VISIBLE]: function(state) {
    state.sl.storesVisible = []
  },
}
