
import isEmpty from 'lodash/isEmpty'
import { createNamespacedHelpers } from 'vuex'
import { sendVerifyEmail } from '../../libs/wcs/my-account'
import {
  ACTION_PROFILE_RESET_ERROR,
  ACTION_PROFILE_DETAILS,
  ACTION_UPDATE_BREADCRUMB,
  ACTION_GET_STATE_AND_ZIP_REGEX,
} from '../../store/actions/my-account'

const { mapActions, mapGetters: mapGettersMyAccount } = createNamespacedHelpers('myAccount')

export default {
  name: 'sgh-my-account-profile',

  props: {
    smsoptin: {
      type: String,
      default: '',
    },
    sitekey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isPopupOpen: false,
    }
  },
  computed: {
    ...mapGettersMyAccount(['orders', 'profileDetails']),
    registrationApprovalStatus() {
      return (
        this.profileDetails?.objectPerson?.personalInfo?.registrationApprovalStatus === 'Approved'
      )
    },
    isLoyaltyProgramEnabled() {
      return sessionStorage.getItem('isLoyaltyProgramEnabled') === 'true'
    },
    isMyAccountContactPreferencesEnabled() {
      return sessionStorage.getItem('isMyAccountContactPreferencesEnabled') === 'true'
    },
    isNotLoyaltyMember() {
      return isEmpty(this.profileDetails.objectPerson.loyaltyStatus)
    },
  },
  beforeMount() {
    this.updateBreadcrumb([
      { label: this.$t('ma_menu_overview'), to: 'overview' },
      { label: this.$t('ma_mno_profile_details') },
    ])
  },
  mounted() {
    this.actionResetProfileError()
    this.actionProfileDetails()
    this.actionGetStateAndZipRegex()
    this.$nextTick(function() {
      this.trackingProfileSection()
    })
    document.onreadystatechange = () => {
      if (document.readyState == 'complete') {
        this.trackingProfileSection()
      }
    }
  },
  methods: {
    ...mapActions({
      actionResetProfileError: ACTION_PROFILE_RESET_ERROR,
      actionProfileDetails: ACTION_PROFILE_DETAILS,
      updateBreadcrumb: ACTION_UPDATE_BREADCRUMB,
      actionGetStateAndZipRegex: ACTION_GET_STATE_AND_ZIP_REGEX,
    }),
    trackingProfileSection() {
      this.$ma.trackEventSPA({ Page_Type: 'Profile', Page_Section1: 'Account', Page_Section2: '' })
    },
    callback() {
      const param = { email: this.profileDetails.objectPerson.loginInfo[0] }
      sendVerifyEmail(param).finally(() => {
        this.$refs.refPop.isOpen = true
      })
    },
  },
}
