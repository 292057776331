import { actions } from '../actions/wishlist'
import { getters } from '../getters/wishlist'
import { mutations } from '../mutations/wishlist'
import { getCurrentCountry } from '../../../scripts/libs/utils/currentCountry'

export const wishlist = {
  namespaced: true,
  state: {
    products: undefined,
    emailMeError: '',
    error: null,
    marketing: {
      emailSubscription: {},
    },
    ui: {
      body: {},
      plpLoadingProducts: false,
      plpLoadingMoreProducts: false,
      plpLoadingFacets: false,
      sidebar: false,
      filterItemClicked: '',
      mobileView: '',
    },
    counterWishlist: window.localStorage.getItem(`SGH_WISHLISTITEMS_${getCurrentCountry()}`)
      ? window.localStorage.getItem(`SGH_WISHLISTITEMS_${getCurrentCountry()}`)
      : '',
    shareInfo: {
      isShared: false,
      sharedBy: '',
    },
    productsCatentryArr: [],
  },
  actions,
  getters,
  mutations,
}
