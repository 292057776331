
import { createNamespacedHelpers } from 'vuex'

import { ACTION_LOGON_RESET_ERROR } from '../../store/actions/my-account'

const { mapActions, mapGetters: mapGettersMyAccount } = createNamespacedHelpers('myAccount')

export default {
  name: 'sgh-my-account-login',
  props: {
    smsoptin: {
      type: String,
      default: '',
    },
    sitekey: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      showLoginForm: this.$route.name === 'login',
    }
  },
  computed: {
    ...mapGettersMyAccount(['myAccountDetails']),
    isLoyaltyEnabled() {
      return sessionStorage.getItem('isLoyaltyProgramEnabled') === 'true'
    },
  },
  mounted() {
    this.$nextTick(function() {
      this.trackingLoginSection()
    })
    document.onreadystatechange = () => {
      if (document.readyState == 'complete') {
        this.trackingLoginSection()
      }
    }
  },
  methods: {
    ...mapActions({
      actionResetError: ACTION_LOGON_RESET_ERROR,
    }),
    showLogin(show) {
      this.showLoginForm = show
      this.actionResetError()
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    },
    trackingLoginSection() {
      this.$ma.trackEventSPA({
        Page_Type: 'Login&Register',
        Page_Section1: 'Account',
        Page_Section2: '',
        Page_Design: 'Login&Register',
      })
    },
  },
}
