export const UPDATE_OPEN_MODAL = 'UPDATE_OPEN_MODAL'
export const UPDATE_OPEN_MODAL_SIZE_GUIDE = 'UPDATE_OPEN_MODAL_SIZE_GUIDE'
export const UPDATE_TARGET_BACK = 'UPDATE_TARGET_BACK'

export const mutations = {
  [UPDATE_OPEN_MODAL]: function(state, payload) {
    state.isModalOpen = payload.isModalOpen
    state.componentName = payload.componentName
  },
  [UPDATE_OPEN_MODAL_SIZE_GUIDE]: function(state, payload) {
    state.isSizeGuideOpen = payload.isSizeGuideOpen
    state.componentName = payload.componentName
    state.isModalOpen = payload.isModalOpen
  },
  [UPDATE_TARGET_BACK]: function(state, payload) {
    state.targetBack = payload
  },
}
