
import { createNamespacedHelpers } from 'vuex'
import formJson from '../../../../helpers/formHelperMyAccountLogin'
import { validationMixin } from 'vuelidate'
import { ACTION_RESET_URL } from '../../../../store/actions/my-account'

const { mapActions } = createNamespacedHelpers('myAccount')
export default {
  name: 'sgh-recovery',
  mixins: [validationMixin],
  props: {
    isPopup: {
      type: Boolean,
      default: false,
    },
    sitekey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      numberModule: 5,
      inputInBlur: {},
      isError: false,
      loading: false,
      form: {
        newEmail: this.isExpiredPassword() ? this.getEmailParameter() : '',
      },
    }
  },
  validations() {
    return {
      form: this.rules,
    }
  },
  computed: {
    formFields() {
      return formJson()[this.numberModule] ? formJson()[this.numberModule] : null
    },

    rules() {
      let validations = {}
      this.formFields.fields.map(item => {
        Object.assign(validations, item.validate)
      })
      return validations
    },
  },
  mounted() {
    if (this.sitekey) {
      window.grecaptcha.render('reCaptcha', {
        sitekey: this.sitekey,
      })
    }
  },
  methods: {
    ...mapActions({
      actionResetUrl: ACTION_RESET_URL,
    }),
    confirmSubscriptionRecoveryPassword() {
      this.$v.form.$touch()
      this.$root.$emit('loading')
      if (this.$v.form.$invalid) {
        this.$root.$emit('!loading')
        return false
      }
      if (this.sitekey) {
        let recaptchField = document.querySelector('[id^="g-recaptcha"]')
        if (!recaptchField.value) {
          recaptchField.closest('div').classList.add('g-recaptcha-invalid')
          return false
        } else {
          recaptchField.closest('div').classList.remove('g-recaptcha-invalid')
        }
      }

      this.sendEmail()
    },

    sendEmail() {
      let recaptchValue,
        dataToSend = {}
      dataToSend.email = this.form.newEmail.toLowerCase()
      if (this.sitekey) {
        let recaptchField = document.querySelector('[id^="g-recaptcha"]')
        recaptchValue = recaptchField.value
        dataToSend.recaptcha = recaptchValue
      }
      this.actionResetUrl(dataToSend).then(resp => {
        if (resp) {
          this.$root.$emit('isSuccess', this.form.newEmail)
        } else {
          this.trackErrorRecoveryPassword()
        }
      })
    },

    resetLoader() {
      this.loading = false
      this.$root.$emit('!loading')
      this.$root.$emit('closeOverlay')
    },

    applyValidationClass(fieldName) {
      if (
        this.$v.form[fieldName].$error &&
        this.$v.form[fieldName].$dirty &&
        !this.inputInBlur[`${fieldName}`]
      ) {
        return 'border-red-berry error'
      } else if (!this.$v.form[fieldName].$error && this.$v.form[fieldName].$dirty) {
        return 'valid'
      }
    },
    onBlur(fieldName) {
      setTimeout(() => {
        this.inputInBlur = { ...this.inputInBlur, [fieldName]: false }
      }, 500)
    },
    onFocus(fieldName) {
      this.inputInBlur = { ...this.inputInBlur, [fieldName]: true }
    },
    eraseText(fieldName) {
      this.$v.form[fieldName].$model = ''
      this.showPassword = false
      setTimeout(() => {
        this.$refs[fieldName][0].focus()
      }, 500)
    },
    trackErrorRecoveryPassword() {
      this.$ma.trackException({
        source: '',
        code: '',
        detail: 'Error',
        message: '',
      })
    },
    isExpiredPassword() {
      return window.location.search.includes('expiredPassword') ? true : false
    },
    getEmailParameter() {
      var searchParam = window.location.search,
        expiredParam = new URLSearchParams(searchParam)
      return decodeURIComponent(expiredParam.get('expiredPassword'))
    },
  },
}
