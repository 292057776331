import get from 'lodash/get'
import { mutations } from '../mutations/labels'

export const labels = {
  state: {
    translations: {},
  },
  getters: {
    translation: state => key => get(state.translations, key, key),
  },
  mutations,
}
