import { actions } from '../actions/store-locator'
import { actionsFavouriteStore } from '../actions/shared'
import { getters } from '../getters/store-locator'
import { mutations } from '../mutations/store-locator'
import { mutationsFavouriteStore } from '../mutations/shared'

export const storeLocator = {
  namespaced: true,
  state: {
    sl: {
      stores: [],
      location: null,
      latitude: null,
      longitude: null,
      radius: null,
      showTab: 'map',
      filters: [],
      filtersCheckedList: {
        storeType: [],
        storeBrands: [],
        bookingAvailable: [],
        rxAvailable: [],
        stellaAvailable: [],
      },
      filtersAppliedList: {
        storeType: [],
        storeBrands: [],
        bookingAvailable: [],
        rxAvailable: [],
        stellaAvailable: [],
      }, //used to reset active filters when cta not pressed
      storesFiltered: [],
      storesVisible: [],
    },
  },

  actions: {
    ...actions,
    ...actionsFavouriteStore,
  },
  getters,
  mutations: {
    ...mutations,
    ...mutationsFavouriteStore,
  },
}
