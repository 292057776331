import { getGiftCardBalance } from '../../libs/wcs/get-support'
import { UPDATE_GIFTCARD_BALANCE, UPDATE_GIFTCARD_BALANCE_ERROR } from '../mutations/get-support'

export const ACTION_GIFTCARD_BALANCE = 'ACTION_GIFTCARD_BALANCE'
export const ACTION_RESET_GIFTCARD_BALANCE = 'ACTION_RESET_GIFTCARD_BALANCE'

export const actions = {
  [ACTION_GIFTCARD_BALANCE]: async function(
    { commit },
    { storeId, catalogId, langId, giftCardPin, giftCardNumber, gRecaptchaResponse }
  ) {
    let data
    try {
      data = await getGiftCardBalance(
        storeId,
        catalogId,
        langId,
        giftCardPin,
        giftCardNumber,
        gRecaptchaResponse
      )
      data.error
        ? commit(UPDATE_GIFTCARD_BALANCE_ERROR, data.error)
        : commit(UPDATE_GIFTCARD_BALANCE, data)
    } catch (e) {
      console.log('Autocomplete response: server error ', e)
    }
  },

  [ACTION_RESET_GIFTCARD_BALANCE]: function({ commit }) {
    commit(UPDATE_GIFTCARD_BALANCE, {})
  },
}
