import makeDebug from 'debug'
const debug = makeDebug('store:action')
import { getExtraFacets } from '../../libs/utils/url'

import {
  getPlpProducts,
  getPlpFacets,
  getPlpFacetStart,
  getPlpProductsStart,
  getPlpFilteredCMSBanners,
  getProductsVariants,
} from '../../libs/wcs/plp'

import { getWishlistProducts } from '../../libs/wcs/wishlist'
import {
  UPDATE_LOAD_PLP_PRODUCTS,
  UPDATE_LOAD_PLP_FACETS,
  UPDATE_UI,
  UPDATE_UI_BODY,
  UPDATE_FACETS,
  UPDATE_RESET_PAGINATION_DATA,
  UPDATE_NEXT_PAGE_URL,
  UPDATE_URL,
  UPDATE_IS_FACETS_FIRST_LOAD,
  UPDATE_FACETS_COUNT,
  UPDATE_CLONE_FILTER_OPTIONS_LIST,
  UPDATE_FILTER_CATEGORY_LABEL,
  UPDATE_FILTERED_CMS_BANNERS,
  UPDATE_IDS_FILTERS_CHECKED,
  UPDATE_REMOVE_FACET_COUNT,
  UPDATE_EXTRAFACETS,
  UPDATE_LOAD_PLP_PRODUCTS_VARIANTS,
  UPDATE_RETRIEVE_FAVORITE_LIST,
  UPDATE_ADD_REMOVE_FAVORITE,
} from '../mutations/plp'

export const ACTION_LOAD_PLP = 'ACTION_LOAD_PLP'
export const ACTION_LOAD_PLP_PRODUCTS = 'ACTION_LOAD_PLP_PRODUCTS'
export const ACTION_LOAD_PLP_FACETS = 'ACTION_LOAD_PLP_FACETS'
export const ACTION_TOGGLE_PLP_LOADING_MORE_PRODUCT = 'ACTION_TOGGLE_PLP_LOADING_MORE_PRODUCT'
export const ACTION_TOGGLE_PLP_SORT = 'ACTION_TOGGLE_PLP_SORT'
export const ACTION_TOGGLE_PLP_FILTER = 'ACTION_TOGGLE_PLP_FILTER'
export const ACTION_UPDATE_FACETS = 'ACTION_UPDATE_FACETS'
export const ACTION_LOAD_MORE = 'ACTION_LOAD_MORE'
export const ACTION_TOGGLE_SIDEBAR = 'ACTION_TOGGLE_SIDEBAR'
export const ACTION_CLONE_FILTER_OPTIONS_LIST = 'ACTION_CLONE_FILTER_OPTIONS_LIST'
export const ACTION_UPDATE_FILTER_CATEGORY_LABEL = 'ACTION_UPDATE_FILTER_CATEGORY_LABEL'
export const ACTION_TOGGLE_FILTER_ITEM = 'ACTION_TOGGLE_FILTER_ITEM'
export const ACTION_UPDATE_IDS_FILTERS_CHECKED = 'ACTION_UPDATE_IDS_FILTERS_CHECKED'
export const ACTION_REMOVE_FACET_COUNT = 'ACTION_REMOVE_FACET_COUNT'
export const ACTION_UPDATE_FILTERED_CMS_BANNERS = 'ACTION_UPDATE_FILTERED_CMS_BANNERS'
export const ACTION_RETRIEVE_FAVORITE_LIST = 'ACTION_RETRIEVE_FAVORITE_LIST'
export const ACTION_ADD_REMOVE_FAVORITE = 'ACTION_ADD_REMOVE_FAVORITE'

const useAlgolia = window.algoliaConfig?.isActive || false

export const actions = {
  //action triggered only on first page load:
  [ACTION_LOAD_PLP]: async function({ commit, dispatch, state }) {
    //save in state extra facets from url which are not selectable in ui but needs to be stored
    const extraFacets = getExtraFacets()
    let checkedFilters = []
    commit(UPDATE_EXTRAFACETS, extraFacets)

    // first retrieve data from filters because they contain the orderBy clause
    debug('%s payload %o', ACTION_LOAD_PLP)
    commit(UPDATE_UI, { plpLoadingFacets: true })

    if (window.location.search === '' && useAlgolia) {
      //if url is empty first call products then facets to improve LCP since we don't need facets first
      const apiResponse = await getPlpProductsStart({
        idsFiltersCheckedList: [],
        extraFacets: [],
        orderBy: 'default',
      })
      const { data = null, mainProducts = null, options = null } = apiResponse || {}

      if (data) {
        commit(UPDATE_LOAD_PLP_PRODUCTS, { ...data })
        commit(UPDATE_RESET_PAGINATION_DATA, { ...data }) //#SGHDP-9070 pagination data are altered at first load if currentPage>1
        commit(UPDATE_NEXT_PAGE_URL, { ...data })
        commit(UPDATE_UI, {
          plpLoadingProducts: false,
          plpLoadingMoreProducts: false,
          mobileView: state.ui.mobileView ? state.ui.mobileView : data.mobileView,
        })
        if (useAlgolia) {
          const productsAndVariants = await getProductsVariants({ mainProducts, options })
          commit(UPDATE_LOAD_PLP_PRODUCTS_VARIANTS, { ...productsAndVariants })
        }
      }
      if (useAlgolia) {
        dispatch(ACTION_RETRIEVE_FAVORITE_LIST)
      }
      const { facet, facetParams, facetToSelect } = await getPlpFacetStart({ extraFacets })
      // create facet for the panel on right, sort option list
      commit(UPDATE_LOAD_PLP_FACETS, facet)
      // add the query parameter (if present) to initialize vue state
      commit(UPDATE_FACETS, facetParams)
      // add the check to filters that should be selected
      facetToSelect.forEach(f => {
        checkedFilters.push(f.id)
        commit(UPDATE_IDS_FILTERS_CHECKED, f)
      })
      const allFacets = facet.filterOptionsList
      if (checkedFilters.length > 0)
        getPlpFilteredCMSBanners({ filters: allFacets, checkedFilters }).then(data =>
          dispatch(ACTION_UPDATE_FILTERED_CMS_BANNERS, data)
        )
      if (!state.isFacetsFirstLoad) {
        commit(UPDATE_IS_FACETS_FIRST_LOAD, true)
        commit(UPDATE_FACETS_COUNT, { facet })
      }
      commit(UPDATE_UI, { plpLoadingFacets: false })
    } else {
      //do things as before: first facets then products: can be improved avoiding repetition of code in if - else
      const { facet, facetParams, facetToSelect } = await getPlpFacetStart({ extraFacets })
      // create facet for the panel on right, sort option list
      commit(UPDATE_LOAD_PLP_FACETS, facet)
      // add the query parameter (if present) to initialize vue state
      commit(UPDATE_FACETS, facetParams)
      // add the check to filters that should be selected
      facetToSelect.forEach(f => {
        checkedFilters.push(f.id)
        commit(UPDATE_IDS_FILTERS_CHECKED, f)
      })
      const allFacets = facet.filterOptionsList
      if (checkedFilters.length > 0)
        getPlpFilteredCMSBanners({
          filters: allFacets,
          checkedFilters,
        }).then(data => dispatch(ACTION_UPDATE_FILTERED_CMS_BANNERS, data))
      commit(UPDATE_UI, { plpLoadingFacets: false })

      const { data, mainProducts, options } = await getPlpProductsStart({ ...state.facetsSelected })
      if (data) {
        commit(UPDATE_LOAD_PLP_PRODUCTS, { ...data })
        commit(UPDATE_RESET_PAGINATION_DATA, { ...data }) //#SGHDP-9070 pagination data are altered at first load if currentPage>1
        commit(UPDATE_NEXT_PAGE_URL, { ...data })
        commit(UPDATE_UI, {
          plpLoadingProducts: false,
          plpLoadingMoreProducts: false,
          mobileView: state.ui.mobileView ? state.ui.mobileView : data.mobileView,
        })
        commit(UPDATE_URL, { ...state.facetsSelected })
        if (!state.isFacetsFirstLoad) {
          commit(UPDATE_IS_FACETS_FIRST_LOAD, true)
          commit(UPDATE_FACETS_COUNT, { facet })
        }
        if (useAlgolia) {
          const productsAndVariants = await getProductsVariants({ mainProducts, options })
          commit(UPDATE_LOAD_PLP_PRODUCTS_VARIANTS, { ...productsAndVariants })
        }
      }
      if (useAlgolia) {
        dispatch(ACTION_RETRIEVE_FAVORITE_LIST)
      }
    }
  },
  [ACTION_UPDATE_FACETS]: function(
    { dispatch, commit, state },
    { override, updateUi, clearUrl, ...payload }
  ) {
    debug('%s payload %o', ACTION_UPDATE_FACETS, payload)
    commit(UPDATE_FACETS, payload)
    dispatch(ACTION_LOAD_PLP_PRODUCTS, { override, updateUi, clearUrl, ...state.facetsSelected })
    dispatch(ACTION_LOAD_PLP_FACETS)
  },
  //action triggered at any page products update (applying filters, load more):
  [ACTION_LOAD_PLP_PRODUCTS]: async function(
    { commit, dispatch, state },
    { override, updateUi, clearUrl, ...payload }
  ) {
    if (updateUi) {
      commit(UPDATE_UI, updateUi)
    }
    debug('%s payload %o', ACTION_LOAD_PLP_PRODUCTS, payload)
    //add extraFacets to payload so they can be evaluated while fetching products
    payload.extraFacets = state.facetsSelected.extraFacets

    const allFacets = state.facets.filterOptionsList

    if (payload.idsFiltersCheckedList.length > 0) {
      getPlpFilteredCMSBanners({
        filters: allFacets,
        checkedFilters: payload.idsFiltersCheckedList,
      }).then(data => dispatch(ACTION_UPDATE_FILTERED_CMS_BANNERS, data))
    } else {
      dispatch(ACTION_UPDATE_FILTERED_CMS_BANNERS, '')
    }

    const data = await getPlpProducts(payload)
    commit(UPDATE_LOAD_PLP_PRODUCTS, { ...data, override })
    commit(UPDATE_UI, { plpLoadingProducts: false, plpLoadingMoreProducts: false })
    commit(UPDATE_URL, { clearUrl, ...payload })
  },
  [ACTION_LOAD_PLP_FACETS]: async function({ commit, state }) {
    // eslint-disable-next-line no-unused-vars
    const { currentPage, ...payload } = state.facetsSelected
    commit(UPDATE_UI, { plpLoadingFacets: true })
    debug('%s payload %o', ACTION_LOAD_PLP_FACETS, payload)
    //add extraFacets to payload so they can be evaluated while fetching facets
    payload.extraFacets = state.facetsSelected.extraFacets

    const data = await getPlpFacets(payload)
    commit(UPDATE_LOAD_PLP_FACETS, data)
    if (!state.isFacetsFirstLoad) {
      commit(UPDATE_IS_FACETS_FIRST_LOAD, true)
    }
    commit(UPDATE_UI, { plpLoadingFacets: false })
  },
  [ACTION_TOGGLE_PLP_LOADING_MORE_PRODUCT]: function({ commit, state }) {
    debug('action %o', ACTION_TOGGLE_PLP_LOADING_MORE_PRODUCT)
    commit(UPDATE_UI, { plpLoadingMoreProducts: !state.ui.plpLoadingProducts })
  },
  [ACTION_TOGGLE_PLP_FILTER]: function({ /*commit,*/ dispatch, state }) {
    debug('%s payload %o', ACTION_TOGGLE_PLP_FILTER, !state.ui.plpLoadingFacets)
    // commit(UPDATE_UI, { plpLoadingFacets: !state.ui.plpLoadingFacets })
    dispatch(ACTION_TOGGLE_SIDEBAR)
  },
  [ACTION_TOGGLE_SIDEBAR]: function({ commit, state }) {
    const sidebar = !state.ui.sidebar
    debug('%s action', ACTION_TOGGLE_SIDEBAR)
    commit(UPDATE_UI, { sidebar: sidebar })
    commit(UPDATE_UI_BODY, { overflow: sidebar ? 'hidden' : '' })
  },
  [ACTION_LOAD_MORE]: function(
    { dispatch, commit, state },
    { override, updateUi, clearUrl, ...payload }
  ) {
    debug('%s payload %o', ACTION_LOAD_MORE, payload)
    commit(UPDATE_FACETS, payload)
    dispatch(ACTION_LOAD_PLP_PRODUCTS, { override, updateUi, clearUrl, ...state.facetsSelected })
  },
  [ACTION_CLONE_FILTER_OPTIONS_LIST]: function({ commit }, payload) {
    commit(UPDATE_CLONE_FILTER_OPTIONS_LIST, payload)
  },
  [ACTION_UPDATE_FILTER_CATEGORY_LABEL]: function({ commit }, payload) {
    commit(UPDATE_FILTER_CATEGORY_LABEL, payload)
  },
  [ACTION_TOGGLE_FILTER_ITEM]: function({ commit }, label) {
    debug('%s payload %o', ACTION_TOGGLE_FILTER_ITEM, label)
    commit(UPDATE_UI, { filterItemClicked: label })
  },
  [ACTION_UPDATE_IDS_FILTERS_CHECKED]: function({ commit, dispatch }, payload) {
    commit(UPDATE_IDS_FILTERS_CHECKED, payload)
    dispatch(ACTION_LOAD_PLP_FACETS)
  },
  [ACTION_REMOVE_FACET_COUNT]: function({ commit }, id) {
    commit(UPDATE_REMOVE_FACET_COUNT, id)
  },
  [ACTION_UPDATE_FILTERED_CMS_BANNERS]: function({ commit }, response) {
    commit(UPDATE_FILTERED_CMS_BANNERS, response)
  },
  [ACTION_ADD_REMOVE_FAVORITE]: function({ commit }, params) {
    commit(UPDATE_ADD_REMOVE_FAVORITE, params)
  },
  [ACTION_RETRIEVE_FAVORITE_LIST]: async function({ commit }, catentryId) {
    const wishlistItems = await getWishlistProducts()
    commit(UPDATE_RETRIEVE_FAVORITE_LIST, { wishlistItems, catentryId })
  },
}
