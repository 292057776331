export const getters = {
  products: state => state.products || undefined,
  ui: state => state.ui || {},
  marketing: state => state.marketing || {},
  emailMeError: state => state.emailMeError || false,
  error: state => state.error || null,
  sortOptionsList: state => state.sortOptionsList || [],
  shareOptionsList: state => state.shareOptionsList || [],
  shareInfo: state => state.shareInfo || {},
  counterWishlist: state => state.counterWishlist || '',
  productsCatentryArr: state => state.productsCatentryArr || [],
}
