
import { createNamespacedHelpers } from 'vuex'

import { ACTION_LOAD_WISHLIST } from '../../store/actions/wishlist'
import { ACTION_UPDATE_BREADCRUMB } from '../../store/actions/my-account'
import IntersectionObserverComponent from '../../directives/intersectionObserver.vue'

const {
  mapActions: mapActionsMyAccount,
  mapGetters: mapGettersMyAccount,
} = createNamespacedHelpers('myAccount')
const { mapActions: mapActionsWishlist, mapGetters: mapGettersWishlist } = createNamespacedHelpers(
  'wishlist'
)

export default {
  name: 'sgh-my-account-overview',
  components: {
    IntersectionObserverComponent,
  },

  data() {
    return {
      isLoyaltyProgramEnabled: sessionStorage.getItem('isLoyaltyProgramEnabled') == 'true',
    }
  },

  computed: {
    ...mapGettersMyAccount(['orders', 'profileDetails']),
    ...mapGettersWishlist(['products']),
    productsInOverview() {
      return this.products.slice(0, 6)
    },
    isMobile() {
      return this.$mq == 'tablet' || this.$mq == 'tabletLandscape'
    },
  },

  beforeMount() {
    this.updateBreadcrumb([{ label: this.$t('ma_menu_overview') }])
  },
  mounted() {
    this.$nextTick(function() {
      this.trackingOverviewSection()
    })
    document.onreadystatechange = () => {
      if (document.readyState == 'complete') {
        this.trackingOverviewSection()
      }
    }
  },

  methods: {
    ...mapActionsWishlist({
      loadWishlist: ACTION_LOAD_WISHLIST,
    }),
    ...mapActionsMyAccount({
      updateBreadcrumb: ACTION_UPDATE_BREADCRUMB,
    }),

    trackingOverviewSection() {
      this.$ma.trackEventSPA({
        Page_Type: 'UserSummary',
        Page_Section1: 'Account',
        Page_Section2: '',
      })
    },
    bannerEvent() {
      this.$router.push({ name: 'details' })
    },
  },
}
