import SghLoyaltyBanner from '../components/loyalty/loyalty-banner.vue'
import sghPayOverTime from '../components/pay-over-time/sgh-pay-over-time.vue'
import SLPComponent from '../components/sun-love-protection/sun-love-protection.vue'
import i18n from '../i18n-setup'
//init global object
window.exposedSGHApi = {}

export const registerExposedMethods = vuexStore => {
  registrLoyaltyBannerComponent(vuexStore)
  registerSLPComponent(vuexStore)
  registerIntallmentComponent(vuexStore)
  registerAndAppendInstallmentComponent(vuexStore)
}

const registrLoyaltyBannerComponent = vuexStore => {
  window.exposedSGHApi.injectLoyaltyBanner = (nodeItem, points) => {
    const loayaltyBannerConstructor = window.Vue.extend(SghLoyaltyBanner)
    const loyaltyBannerInstance = new loayaltyBannerConstructor({
      store: vuexStore,
      i18n,
      propsData: {
        points,
      },
    })
    loyaltyBannerInstance.$mount()
    nodeItem?.appendChild(loyaltyBannerInstance.$el)
  }
}

const registerSLPComponent = vuexStore => {
  window.exposedSGHApi.injectSLPComponent = (nodeItem, insurance, parentItemId) => {
    const SLPComponentConstructor = window.Vue.extend(SLPComponent)
    const SLPComponentInstance = new SLPComponentConstructor({
      store: vuexStore,
      i18n,
      propsData: {
        insurance,
        parentItemId,
      },
    })
    SLPComponentInstance.$mount()
    nodeItem?.appendChild(SLPComponentInstance.$el)
  }
}

const registerIntallmentComponent = vuexStore => {
  window.exposedSGHApi.injectInstallmentComponent = nodeItem => {
    new window.Vue({
      el: nodeItem,
      i18n,
      store: vuexStore,
    })
  }
}

const registerAndAppendInstallmentComponent = vuexStore => {
  window.exposedSGHApi.injectAndAppendInstallmentComponent = (nodeItem, pageType = 'wcs') => {
    const installmentConstructor = window.Vue.extend(sghPayOverTime)
    const installmentInstance = new installmentConstructor({
      store: vuexStore,
      i18n,
      propsData: {
        pageType,
      },
    })
    installmentInstance.$mount()
    nodeItem?.appendChild(installmentInstance.$el)
  }
}
