import wcs from '../libs/wcs'

import { actions } from './actions'
import { mutations } from './mutations'

// modules
import { countryRedirect } from './modules/country-redirect'
import { earlyAccess } from './modules/early-access'
import { frameAdvisor } from './modules/frame-advisor'
import { labels } from './modules/labels'
import { minicart } from './modules/minicart'
import { myAccount } from './modules/my-account'
import { order } from './modules/order'
import { pdp } from './modules/pdp'
import { plp } from './modules/plp'
import { search } from './modules/search'
import { shortcode } from './modules/shortcode'
import { sidebar } from './modules/sidebar'
import { sizeAdvisor } from './modules/size-advisor'
import { storeLocator } from './modules/store-locator'
import { wishlist } from './modules/wishlist'
import { getSupport } from './modules/get-support'
import { ui } from './modules/ui'

const storeConfig = wcs.getStoreConfig()

export default Vuex => {
  const store = new Vuex.Store({
    modules: {
      countryRedirect,
      earlyAccess,
      frameAdvisor,
      labels,
      minicart,
      myAccount,
      order,
      pdp,
      plp,
      search,
      shortcode,
      sidebar,
      sizeAdvisor,
      storeLocator,
      wishlist,
      getSupport,
      ui,
    },
    state: {
      openModalName: '',
      storeConfig,
    },
    getters: {
      storeConfig: state => state.storeConfig || {},
    },
    mutations,
    actions,
  })
  return store
}
