import { actions } from '../actions/early-access'
import { getters } from '../getters/early-access'
import { mutations } from '../mutations/early-access'

export const earlyAccess = {
  namespaced: true,
  state: {
    earlyaccessDetails: {
      error: {},
      success: false,
    },
  },
  actions,
  getters,
  mutations,
}
