export const UPDATE_ERROR_MESSAGE_EARLYACCESS = 'UPDATE_ERROR_MESSAGE_EARLYACCESS'
export const UPDATE_EARLYACCESS_SUBSCRIBE = 'UPDATE_EARLYACCESS_SUBSCRIBE'
export const UPDATE_SERVER_ERRORS = 'UPDATE_SERVER_ERRORS'

export const mutations = {
  [UPDATE_ERROR_MESSAGE_EARLYACCESS]: function(state, payload) {
    state.earlyaccessDetails.error = JSON.parse(JSON.stringify(payload))
  },
  [UPDATE_EARLYACCESS_SUBSCRIBE]: function(state, payload) {
    state.earlyaccessDetails.success = payload
  },
  [UPDATE_SERVER_ERRORS]: function(state, payload) {
    delete state.earlyaccessDetails.error[`${payload}`]
  },
}
