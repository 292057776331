export const ACTION_LOAD_SHORTCODE = 'ACTION_LOAD_SHORTCODE'

import { UPDATE_SHORTCODE, UPDATE_SHORTCODE_LOADING } from '../mutations/shortcode'

import { getShortcode } from '../../libs/wcs/shortcode'
import { getCurrentLocale } from '../../libs/utils/currentCountry'

export const actions = {
  [ACTION_LOAD_SHORTCODE]: async function({ commit }, isDataSession) {
    commit(UPDATE_SHORTCODE_LOADING, true)
    let data
    if (isDataSession) {
      data = JSON.parse(isDataSession)
    } else {
      data = await getShortcode()
      const jsonSession = JSON.stringify(data)
      sessionStorage.setItem(`SGH_SHORTCODE_${getCurrentLocale()}`, jsonSession)
    }

    commit(UPDATE_SHORTCODE, data)
    commit(UPDATE_SHORTCODE_LOADING, false)
  },
}
