import { formatPrice } from '../../libs/utils/string'

export const UPDATE_ORDER_DETAILS = 'UPDATE_ORDER_DETAILS'
export const UPDATE_ORDER_ITEM_CHECKED = 'UPDATE_ORDER_ITEM_CHECKED'
export const UPDATE_SAVE_RETURN_DATA = 'UPDATE_SAVE_RETURN_DATA'
export const UPDATE_ORDER_DELIVERY_CHECKED = 'UPDATE_ORDER_DELIVERY_CHECKED'
export const UPDATE_ORDER_DELIVERY_EMPTY = 'UPDATE_ORDER_DELIVERY_EMPTY'
export const UPDATE_NEW_ORDER_DATA = 'UPDATE_NEW_ORDER_DATA'
export const UPDATE_SAVE_EMAIL = 'UPDATE_SAVE_EMAIL'
export const UPDATE_REASON_SELECT = 'UPDATE_REASON_SELECT'
export const UPDATE_NEW_ADDRESS = 'UPDATE_NEW_ADDRESS'
export const UPDATE_ERROR_MESSAGE = 'UPDATE_ERROR_MESSAGE'
export const UPDATE_RETURN_FLOW_STEP = 'UPDATE_RETURN_FLOW_STEP'
export const UPDATE_ERROR_MESSAGEFORM = 'UPDATE_ERROR_MESSAGEFORM'
export const UPDATE_URL_ORDER_LABEL = 'UPDATE_URL_ORDER_LABEL'
export const UPDATE_ERROR_MESSAGE_RETURN_LABEL = 'UPDATE_ERROR_MESSAGE_RETURN_LABEL'
export const UPDATE_ZIPCODE_AUTOCOMPLETE = 'UPDATE_ZIPCODE_AUTOCOMPLETE'
export const UPDATE_EMPTY_ZIPCODE_AUTOCOMPLETE = 'UPDATE_EMPTY_ZIPCODE_AUTOCOMPLETE'
export const UPDATE_ERROR_DOWNLOAD_INVOICE_FILE = 'UPDATE_ERROR_DOWNLOAD_INVOICE_FILE'
export const UPDATE_INVOICE_FILE = 'UPDATE_INVOICE_FILE'
export const UPDATE_ORDER_ITEM_REFUND_AMOUNT = 'UPDATE_ORDER_ITEM_REFUND_AMOUNT'

export const mutations = {
  [UPDATE_ORDER_DETAILS]: function(state, payload) {
    state.orderDetails = payload
  },
  [UPDATE_SAVE_EMAIL]: function(state, payload) {
    state.returnDetails.contact.email1 = payload
    state.returnDetails.contact.firstName = state.orderDetails.name
    state.returnDetails.contact.lastName = state.orderDetails.surname
  },
  [UPDATE_ORDER_ITEM_CHECKED]: function(state, payload) {
    let indexOf = state.returnDetails.itemsChecked.indexOf(payload)

    if (indexOf > -1) {
      //item is already selected -> remove
      state.returnDetails.itemsChecked.splice(indexOf, 1)
    } else {
      //item is not already selected -> push
      state.returnDetails.itemsChecked.push(payload)
    }
  },
  [UPDATE_ORDER_DELIVERY_CHECKED]: function(state, payload) {
    state.returnDetails.data.addressId == payload
      ? (state.returnDetails.data.addressId = null)
      : (state.returnDetails.data.addressId = payload)
  },
  //empty addressId selected and new address fields filled:
  [UPDATE_ORDER_DELIVERY_EMPTY]: function(state) {
    state.returnDetails.data.addressId = null
    state.returnDetails.contact.state = null
    state.returnDetails.contact.city = null
    state.returnDetails.contact.zipCode = null
    state.returnDetails.contact.addressLine = null
    state.returnDetails.contact.municipio = null
    state.returnDetails.contact.colonia = null
  },
  [UPDATE_SAVE_RETURN_DATA]: function(state) {
    state.returnDetails.data.addressId = state.orderDetails.orderAddress.id
    state.returnDetails.data.orderId = state.orderDetails.number
    state.returnDetails.itemsChecked.forEach(element => {
      const { protectionDetails } = element
      let item = {
        orderItemId: element.id,
        type: null,
        reason: null,
        amount: formatPrice(element.price),
        quantity: element.quantity,
      }
      if (!state.returnDetails.data.returnItems.find(x => x.orderItemId === element.id)) {
        state.returnDetails.data.returnItems.push(item)
      }
      if (protectionDetails?.isReturnable && protectionDetails?.orderItemId) {
        const itemProtection = {
          orderItemId: protectionDetails.orderItemId,
          type: null,
          reason: null,
          amount: formatPrice(protectionDetails.offerPrice),
          quantity: '1.0',
        }
        state.returnDetails.data.returnItems.push(itemProtection)
      }
    })
  },
  [UPDATE_REASON_SELECT]: function(state, payload) {
    state.returnDetails.data.returnItems.forEach(item => {
      item.reason = payload ? payload.key : null
      item.type = payload ? payload.type : null
    })
  },
  [UPDATE_NEW_ORDER_DATA]: function(state, payload) {
    state.returnDetails.newOrderId = payload.orderId
  },

  [UPDATE_NEW_ADDRESS]: function(state, payload) {
    state.returnDetails.contact.state = payload.initial
    state.returnDetails.contact.country = payload.country
    state.returnDetails.contact.city = payload.city
    state.returnDetails.contact.zipCode = payload.zip_code
    state.returnDetails.contact.addressLine = payload.text_address
    state.returnDetails.contact.phone1 = payload.phone1
    state.returnDetails.contact.municipio = payload.municipio ? payload.municipio : ''
    state.returnDetails.contact.colonia = payload.colonia ? payload.colonia : ''
  },
  [UPDATE_ERROR_MESSAGE]: function(state, payload) {
    state.returnDetails.error = payload
  },
  [UPDATE_RETURN_FLOW_STEP]: function(state, payload) {
    payload == 'increment' ? state.returnDetails.step++ : state.returnDetails.step--
  },
  [UPDATE_ERROR_MESSAGEFORM]: function(state, payload) {
    state.orderDetails.error = payload.data['Error message'] ? payload.data['Error message'] : ''
    state.orderDetails.errorId = payload.status ? payload.status.toString() : ''
  },
  [UPDATE_URL_ORDER_LABEL]: function(state, payload) {
    state.orderDetails.returnLabel = payload
  },
  [UPDATE_ERROR_MESSAGE_RETURN_LABEL]: function(state, payload) {
    state.orderDetails.errorReturnLabel = payload
  },
  [UPDATE_ZIPCODE_AUTOCOMPLETE]: function(state, payload) {
    state.returnDetails.zipcodeAutocomlete = payload
  },
  [UPDATE_EMPTY_ZIPCODE_AUTOCOMPLETE]: function(state) {
    state.returnDetails.zipcodeAutocomlete = []
  },
  [UPDATE_ERROR_DOWNLOAD_INVOICE_FILE]: function(state, payload) {
    state.orderDetails.errorDownloadInoviceFile = payload
  },
  [UPDATE_INVOICE_FILE]: function(state, payload) {
    state.orderDetails.invoiceFile = payload
  },
  [UPDATE_ORDER_ITEM_REFUND_AMOUNT]: function(state, payload) {
    state.returnDetails.itemsChecked.forEach(
      item =>
        (item.price = payload
          .find(product => product.orderItemId == item.id)
          .refundPrice.toString())
    )
  },
}
