
import { createNamespacedHelpers } from 'vuex'
import {
  ACTION_LOAD_FAVOURITE_STORES,
  ACTION_LOAD_APPOINTMENTS_BOOKING,
  ACTION_UPDATE_BREADCRUMB,
} from '../../store/actions/my-account'
import { loadSectionTranslations } from '../../i18n-setup'
import { getCurrentCountry } from '../../libs/utils/currentCountry'
const { mapActions, mapGetters: mapGettersMyAccount } = createNamespacedHelpers('myAccount')

export default {
  name: 'sgh-my-account-book-appointment',

  data() {
    return {
      configuratorPopup: {
        size: 'xs',
        posclose: 'right-top',
        margin: 'my-5.6rem mdr:my-0',
        areLabelsLoaded: false,
      },
    }
  },

  computed: {
    ...mapGettersMyAccount(['favouriteStores', 'appointmentsBooking']),
    defaultStore() {
      for (let store in this.favouriteStores) {
        if (this.favouriteStores[store].default === true) {
          return this.favouriteStores[store]
        }
      }
      return false
    },
  },
  beforeMount() {
    loadSectionTranslations('my-account-new').then(() => {
      this.areLabelsLoaded = true
      this.updateBreadcrumb([
        { label: this.$t('ma_menu_overview'), to: 'overview' },
        { label: this.$t('ma_menu_get_an_appointment') },
      ])
    })
  },
  mounted() {
    this.loadFavouriteStores()
    this.loadAppointmentsBooking()
  },

  methods: {
    ...mapActions({
      loadFavouriteStores: ACTION_LOAD_FAVOURITE_STORES,
      loadAppointmentsBooking: ACTION_LOAD_APPOINTMENTS_BOOKING,
      updateBreadcrumb: ACTION_UPDATE_BREADCRUMB,
    }),
    bookingButton() {
      this.defaultStore && this.defaultStore.booking
        ? this.togglePopup()
        : (window.location.href = this.$t('ma_sl_link_with_book_appointment'))
    },
    getBookingUrl() {
      return `/${getCurrentCountry()}/book-appointment?locationId=${this.defaultStore.id}`
    },
    togglePopup() {
      this.$refs.refPop.isOpen = !this.$refs.refPop.isOpen
    },
  },
}
