import makeDebug from 'debug'
const debug = makeDebug('store:action')

export const ACTION_LOAD_SL = 'ACTION_LOAD_SL'
export const ACTION_UPDATE_SL_FILTERS_CHECKED = 'ACTION_UPDATE_SL_FILTERS_CHECKED'
export const ACTION_SHOW_TAB = 'ACTION_SHOW_TAB'
export const ACTION_APPLY_SL_FILTERS = 'ACTION_APPLY_SL_FILTERS'
export const ACTION_SL_EMPTY_FILTERS = 'ACTION_SL_EMPTY_FILTERS'
export const ACTION_SL_REVERT_FILTERS = 'ACTION_SL_REVERT_FILTERS'
export const ACTION_SL_SET_ACTIVE_STORE = 'ACTION_SL_SET_ACTIVE_STORE'

import {
  UPDATE_LOAD_SL,
  UPDATE_SL_STORES_FILTERED,
  UPDATE_SL_FILTERS_CHECKED,
  UPDATE_SHOW_TAB,
  UPDATE_SL_STORES_VISIBLE,
  UPDATE_SL_APPLIED_FILTERS,
  UPDATE_SL_EMPTY_FILTERS,
  UPDATE_REVERT_SL_FILTERS_TO_APPLIED,
  UPDATE_REVERT_SL_STORES_TO_VISIBLE,
  UPDATE_SL_SET_ACTIVE_STORE,
} from '../mutations/store-locator'

import { getStoreLocatorList } from '../../libs/wcs/store-locator'

export const actions = {
  [ACTION_LOAD_SL]: async function({ commit }, position = {}) {
    const data = await getStoreLocatorList({ position })
    commit(UPDATE_LOAD_SL, { ...data, location: position.location || '' })
  },
  [ACTION_UPDATE_SL_FILTERS_CHECKED]: function({ commit }, payload) {
    debug('%s payload %o', ACTION_UPDATE_SL_FILTERS_CHECKED, payload)
    commit(UPDATE_SL_FILTERS_CHECKED, payload)
    commit(UPDATE_SL_STORES_FILTERED)
  },
  [ACTION_SHOW_TAB]: function({ commit }, tabName) {
    commit(UPDATE_SHOW_TAB, tabName)
  },
  [ACTION_APPLY_SL_FILTERS]: function({ commit }, payload) {
    debug('%s payload %o', ACTION_APPLY_SL_FILTERS, payload)
    commit(UPDATE_SL_STORES_VISIBLE)
    commit(UPDATE_SL_APPLIED_FILTERS)
  },
  //empty checked filters on clear all
  [ACTION_SL_EMPTY_FILTERS]: function({ commit }) {
    commit(UPDATE_SL_EMPTY_FILTERS)
    // uncomment these 3 lines if clear all should also apply filters reset:
    commit(UPDATE_SL_STORES_VISIBLE)
    commit(UPDATE_SL_APPLIED_FILTERS)
    commit(UPDATE_SHOW_TAB, 'list')
  },
  //reverts filters status to last cta applied
  [ACTION_SL_REVERT_FILTERS]: function({ commit }) {
    commit(UPDATE_REVERT_SL_FILTERS_TO_APPLIED)
    commit(UPDATE_REVERT_SL_STORES_TO_VISIBLE)
  },
  [ACTION_SL_SET_ACTIVE_STORE]: function({ commit }, storeId) {
    debug('%s payload %o', ACTION_SL_SET_ACTIVE_STORE, storeId)
    commit(UPDATE_SL_SET_ACTIVE_STORE, storeId)
  },
}
