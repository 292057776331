export const ACTION_FA_CHECK_SESSION = 'ACTION_FA_CHECK_SESSION'
export const ACTION_FA_GET_USER_PROFILE = 'ACTION_FA_GET_USER_PROFILE'
export const ACTION_FA_RESET_PLP = 'ACTION_FA_RESET_PLP'
export const ACTION_FA_SAVE_UPCS = 'ACTION_FA_SAVE_UPCS'
export const ACTION_FA_SAVE_USER_PROFILE = 'ACTION_FA_SAVE_USER_PROFILE'
export const ACTION_FA_SET_PDP_VISITED_URL = 'ACTION_FA_SET_PDP_VISITED_URL'
export const ACTION_LOAD_FA_PLP_PRODUCTS = 'ACTION_LOAD_FA_PLP_PRODUCTS'
export const ACTION_SWITCH_LANDING = 'ACTION_SWITCH_LANDING'

import {
  UPDATE_FA_CURRENTPAGE,
  UPDATE_FA_LANDING,
  UPDATE_FA_RESET_PLP,
  UPDATE_FA_SAVE_UPCS,
  UPDATE_FA_USER_PROFILE_DATA,
  UPDATE_PDP_VISITED_URL,
  UPDATE_LOAD_FA_PLP_PRODUCTS,
} from '../mutations/frame-advisor'

import {
  getFaPlpProducts,
  getFaUserProfileData,
  saveFaUserProfileData,
} from '../../libs/wcs/frame-advisor'

export const actions = {
  //action triggered at each fa api fetch to populate upcs list
  [ACTION_FA_SAVE_UPCS]: async function({ commit }, payload) {
    commit(UPDATE_FA_SAVE_UPCS, payload)
  },
  //action triggered at any page products update (first load load more):
  [ACTION_LOAD_FA_PLP_PRODUCTS]: async function({ dispatch, commit, state }, pageWanted) {
    const maxFetchableProducts = 36

    let startSlice = state.fa.usedUPCS.length
    let endSlice =
      state.fa.UPCS.length > startSlice + maxFetchableProducts
        ? startSlice + maxFetchableProducts
        : state.fa.UPCS.length
    let UPCSToFetch = state.fa.UPCS.slice(startSlice, endSlice)

    const data = await getFaPlpProducts(UPCSToFetch)
    commit(UPDATE_LOAD_FA_PLP_PRODUCTS, { data, UPCSToFetch })

    if (state.fa.products && state.fa.products.length < pageWanted * state.fa.pagination) {
      dispatch(ACTION_LOAD_FA_PLP_PRODUCTS, pageWanted)
    }
  },
  //action to reset plp
  [ACTION_FA_RESET_PLP]: async function({ commit }) {
    commit(UPDATE_FA_RESET_PLP)
    commit(UPDATE_FA_CURRENTPAGE, 1)
  },
  //action triggered when a plp tile is clicked and a pdp is visited or info should be reset
  [ACTION_FA_SET_PDP_VISITED_URL]: async function({ commit }, visitedPdpUrl) {
    commit(UPDATE_PDP_VISITED_URL, visitedPdpUrl)
  },
  //update showlanding value
  [ACTION_SWITCH_LANDING]: function({ commit }, payload) {
    commit(UPDATE_FA_LANDING, payload)
  },
  //on mount check session to load previous situation
  [ACTION_FA_CHECK_SESSION]: function({ commit }, payload) {
    if (payload) {
      var SGHVueFA = JSON.parse(payload)
      //initialize state with sessionStorage data if present
      if (typeof SGHVueFA.showLanding !== 'undefined')
        commit(UPDATE_FA_LANDING, SGHVueFA.showLanding)
      if (typeof SGHVueFA.currentPage !== 'undefined')
        commit(UPDATE_FA_CURRENTPAGE, SGHVueFA.currentPage)
    }
  },
  // get user profile data
  [ACTION_FA_GET_USER_PROFILE]: async function({ commit }) {
    const data = await getFaUserProfileData()
    commit(UPDATE_FA_USER_PROFILE_DATA, data)
  },
  //update user profile data
  [ACTION_FA_SAVE_USER_PROFILE]: async function({ commit }, payload) {
    const data = await saveFaUserProfileData(payload)
    commit(UPDATE_FA_USER_PROFILE_DATA, data)
  },
}
