
export default {
  name: 'intersection-observer-component',
  props: {
    refName: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      observer: null,
    }
  },
  mounted() {
    this.createObserver()
  },
  destroyed() {
    this.destroyObserver()
  },
  methods: {
    createObserver() {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.$emit('intersect')
            observer.unobserve(entry.target)
          }
        })
      }, this.options)
      observer.observe(this.$refs[this.refName])
      this.observer = observer
    },
    destroyObserver() {
      if (this.observer) {
        this.observer.disconnect()
      }
    },
  },
}
