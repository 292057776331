import { actions } from '../actions/my-account'
import { actionsFavouriteStore } from '../actions/shared'
import { getters } from '../getters/my-account'
import { mutations } from '../mutations/my-account'
import { mutationsFavouriteStore } from '../mutations/shared'

export const myAccount = {
  namespaced: true,
  state: {
    myAccountDetails: {
      crypto: {
        string_md5: '',
        sha256: '',
      },
      loggedIn:
        window.constants &&
        window.constants.ajaxParams.userType.length != 0 &&
        window.constants.ajaxParams.userType === 'R'
          ? true
          : false,
      error: null,
      pages: [
        'overview',
        'details',
        'your-loyalty-program',
        'orderHistory',
        'returnHistory',
        'wishlist',
        'get-an-appointment',
      ],
      pagesWithoutLayout: [
        'login',
        'wishlist',
        'forgotPassword',
        'resetPassword',
        'registration',
        'thankyou',
        'registration-confirmed',
        'registration-failed',
      ],
      pagesWithoutRedirect: ['details', 'thankyou', 'registration-failed'],
    },
    profileDetails: {
      objectPerson: {},
      currentAddressPopup: {},
      error: null,
      zipcodeAutocomlete: [],
      zipCodeValid: true,
      countryInfo: null,
    },
    profileApiBusy: false,
    recoveryDetails: {
      passwordChanged: false,
      passwordChangedError: false,
    },
    passwordRequirements: [],
    orders: {
      orderReturnHistory: {},
      prescriptionId: '',
      orderIdsPrescription: [],
      prescriptionError: '',
      orderReturnLabel: '',
      errorReturnLabel: '',
      errorDownloadInoviceFile: false,
      invoiceFile: null,
      isLoading: false,
    },
    favouriteStores: [],
    appointmentsBooking: {
      upcoming: [],
      past: [],
    },
    loyaltyProductSelected: null,
    loyaltyScriptLoaded: false,
    loyaltyProducts: [],
    breadcrumb: [],
    cartState: [],
  },
  actions: {
    ...actions,
    ...actionsFavouriteStore,
  },
  getters,
  mutations: {
    ...mutations,
    ...mutationsFavouriteStore,
  },
}
