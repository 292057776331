import { getters } from '../getters/size-advisor'
import { actions } from '../actions/size-advisor'
import { mutations } from '../mutations/size-advisor'

export const sizeAdvisor = {
  namespaced: true,
  state: {
    isActiveSa: false,
  },
  getters,
  actions,
  mutations,
}
