class FormHelper {
  constructor(id, type, name, title, errorSingleField, errorLabel, required = true) {
    this.id = id
    this.type = type
    this.name = name
    this.title = title
    this.errorSingleField = errorSingleField
    this.errorLabel = errorLabel
    this.required = required
  }

  getId() {
    return this.id
  }
  getType() {
    return this.type
  }
  getName() {
    return this.name
  }
  getTitle() {
    return this.title
  }
  getErrorSingleField() {
    return this.errorSingleField
  }
  getErrorLabel() {
    return this.errorLabel
  }
  getRequired() {
    return this.required
  }
  getInput() {
    return {
      id: this.getId(),
      type: this.getType(),
      name: this.getName(),
      title: this.getTitle(),
      errorSingleField: this.getErrorSingleField(),
      labelValidation: this.getErrorLabel(),
      required: this.getRequired(),
    }
  }
  getSubfields(subfields) {
    return {
      id: this.getId(),
      type: this.getType(),
      name: this.getName(),
      title: this.getTitle(),
      errorSingleField: this.getErrorSingleField(),
      labelValidation: this.getErrorLabel(),
      required: this.getRequired(),
      subfields: subfields,
    }
  }
  getInputOptions(options) {
    return {
      id: this.getId(),
      type: this.getType(),
      name: this.getName(),
      title: this.getTitle(),
      errorSingleField: this.getErrorSingleField(),
      labelValidation: this.getErrorLabel(),
      required: this.getRequired(),
      values: options,
    }
  }
  getSelect(options) {
    return {
      id: this.getId(),
      type: this.getType(),
      name: this.getName(),
      title: this.getTitle(),
      errorSingleField: this.getErrorSingleField(),
      labelValidation: this.getErrorLabel(),
      required: this.getRequired(),
      values: options,
    }
  }
}

export { FormHelper }
