import { actions } from '../actions/search'
import { getters } from '../getters/search'
import { mutations } from '../mutations/search'

export const search = {
  namespaced: true,
  state: {
    //search autosuggestions:
    search: {
      results: {
        products: null,
        suggestedWords: null,
        spellcheck: [],
        collationQuery: '',
        redirectUrl: '',
        contentResults: null,
      },
      recentlySearched: [],
      isLoading: false,
      overlayIsOpen: false,
      cmsPlacements: [],
      popularLinks: [],
      bestSeller: [],
      noResult: [],
      topBrands: [],
      brandNotSold: {
        isNotSold: false,
      },
      lastSearchTerm: '',
      quizScriptLoaded: false,
    },
    facets: {},
    facetsCount: {},
    facetsSelected: { idsFiltersCheckedList: [], extraFacets: [], orderBy: 'default' },
    filteredCMSBanners: '',
    isFacetsFirstLoad: false,
    isFilterCategoryLabel: true,
    isFilterCategoryHidden: {},
    pagination: {},
    products: [],
    ui: {
      body: {},
      plpLoadingProducts: false,
      plpLoadingMoreProducts: false,
      plpLoadingFacets: false,
      sidebar: false,
      filterItemClicked: '',
      mobileView: '',
    },
    searchTerm: '',
  },
  actions,
  getters,
  mutations,
}
