export const ACTION_CREATE_EARLYACCESS = 'ACTION_CREATE_EARLYACCESS'
export const ACTION_UPDATE_SERVER_ERRORS = 'ACTION_UPDATE_SERVER_ERRORS'

import {
  UPDATE_ERROR_MESSAGE_EARLYACCESS,
  UPDATE_EARLYACCESS_SUBSCRIBE,
  UPDATE_SERVER_ERRORS,
} from '../mutations/early-access'

import { subscribeUser } from '../../libs/wcs/early-access'

export const actions = {
  [ACTION_CREATE_EARLYACCESS]: async function({ commit }, parameters) {
    const data = await subscribeUser(parameters)
    data.result === 'success' || data.result === 'status changed'
      ? commit(UPDATE_EARLYACCESS_SUBSCRIBE, true)
      : commit(UPDATE_ERROR_MESSAGE_EARLYACCESS, data)
  },

  [ACTION_UPDATE_SERVER_ERRORS]: async function({ commit }, value) {
    commit(UPDATE_SERVER_ERRORS, value)
  },
}
