import { actions } from '../actions/country-redirect'
import { getters } from '../getters/country-redirect'
import { mutations } from '../mutations/country-redirect'

export const countryRedirect = {
  namespaced: true,
  state: {
    countries: [
      {
        continue: 'Continue on ',
        flag: 'sgh-icon__flag--au',
        id: 'AU',
        label: 'Australia',
        url: '/au?CID=intlmod',
      },
      {
        continue: 'Continue on ',
        flag: 'sgh-icon__flag--br',
        id: 'BR',
        label: 'Brazil',
        url: '/br?CID=intlmod',
      },
      {
        continue: 'Continue on ',
        flag: 'sgh-icon__flag--ca',
        id: 'CA',
        label: 'Canada',
        url: '/ca?CID=intlmod',
      },
      {
        continue: 'Continue on ',
        flag: 'sgh-icon__flag--fr',
        id: 'FR',
        label: 'France',
        url: '/fr',
      },
      {
        continue: 'DEUTSCHE VERSION BEIBEHALTEN',
        flag: 'sgh-icon__flag--de',
        id: 'DE',
        label: 'Germany',
        url: '/de',
      },
      {
        continue: 'QUÉDATE EN MÉXICO',
        flag: 'sgh-icon__flag--mx',
        id: 'MX',
        label: 'México',
        url: '/mx?CID=intlmod',
      },
      {
        continue: 'Continue on ',
        flag: 'sgh-icon__flag--nl',
        id: 'NL',
        label: 'Netherlands',
        url: '/nl?CID=intlmod',
      },
      {
        continue: 'Continue on ',
        flag: 'sgh-icon__flag--nz',
        id: 'NZ',
        label: 'New Zealand',
        url: '/nz?CID=intlmod',
      },
      {
        continue: 'Continue on ',
        flag: 'sgh-icon__flag--pt',
        id: 'PT',
        label: 'Portugal',
        url: 'http://pt.sunglasshut.com/pt',
      },
      {
        continue: 'Continue on ',
        flag: 'sgh-icon__flag--za',
        id: 'ZA',
        label: 'South Africa',
        url: '/za?CID=intlmod',
      },
      {
        continue: 'Continue on ',
        flag: 'sgh-icon__flag--es',
        id: 'ES',
        label: 'Spain',
        url: '/es?CID=intlmod',
      },
      {
        continue: 'Continue on ',
        flag: 'sgh-icon__flag--ae',
        id: 'AE',
        label: 'United Arab Emirates',
        url: 'https://ae.sunglasshut.com/?CID=intlmod',
      },
      {
        continue: 'Continue on ',
        flag: 'sgh-icon__flag--gb',
        id: 'UK',
        label: 'United Kingdom',
        url: '/uk?CID=intlmod',
      },
      {
        continue: 'Continue on ',
        flag: 'sgh-icon__flag--us',
        id: 'US',
        label: 'United States',
        url: '/us?CID=intlmod',
      },
    ],
    popupClosed: !!sessionStorage.getItem('GEOPOPUP_CLOSED_BY_USER') || false,
  },
  actions,
  getters,
  mutations,
}
