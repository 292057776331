export const UPDATE_TOGGLE_FAVOURITE_STORE = 'UPDATE_TOGGLE_FAVOURITE_STORE'
export const UPDATE_DEFAULT_FAVOURITE_STORE = 'UPDATE_DEFAULT_FAVOURITE_STORE'
export const UPDATE_REMOVE_MYACCOUNT_FAVOURITE_STORE = 'UPDATE_REMOVE_MYACCOUNT_FAVOURITE_STORE'

export const mutationsFavouriteStore = {
  [UPDATE_TOGGLE_FAVOURITE_STORE]: function(state, payload) {
    const indexFoundStore = state.sl.stores.findIndex(element => element.id == payload.storeId)
    state.sl.stores[indexFoundStore].favourite = payload.favourite
  },
  [UPDATE_DEFAULT_FAVOURITE_STORE]: function(state, index) {
    const indexDefautlOld = state.favouriteStores.findIndex(element => element.default == true)
    if (indexDefautlOld > -1) state.favouriteStores[indexDefautlOld].default = false
    state.favouriteStores[index].default = true
  },
  [UPDATE_REMOVE_MYACCOUNT_FAVOURITE_STORE]: function(state, storeId) {
    const removeStoreIndex = state.favouriteStores.findIndex(element => element.id == storeId)
    state.favouriteStores.splice(removeStoreIndex, 1)
  },
}
