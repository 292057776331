
import { createNamespacedHelpers } from 'vuex'
import qs from 'qs'
import { ACTION_LOAD_WISHLIST } from '../../store/actions/wishlist'
import { ACTION_PROFILE_DETAILS } from '../../store/actions/my-account'

const { mapActions: mapActionsWishlist, mapGetters: mapGettersWishlist } = createNamespacedHelpers(
  'wishlist'
)
const { mapActions, mapGetters: mapGettersMyAccount } = createNamespacedHelpers('myAccount')

export default {
  name: 'sgh-my-account-wishlist',

  computed: {
    ...mapGettersWishlist(['products', 'ui', 'shareInfo']),
    ...mapGettersMyAccount(['myAccountDetails']),
    wishlistExpired() {
      return !this.showProducts && this.shareInfo.isShared
    },
    showProducts() {
      const productsExist = this.products && this.products.length > 0
      return productsExist
    },
    headTitle() {
      if (this.showProducts) {
        if (this.shareInfo.isShared) {
          return this.shareInfo.sharedBy
            ? this.$t('ma_sharer', [this.shareInfo.sharedBy])
            : this.$t('ma_shared_wishlist')
        }
        return this.$t('ma_your_wishlist')
      }
      return this.$t('ma_wnl_your_isempty')
    },
  },
  created() {
    const currentParams = qs.parse(decodeURIComponent(window.location.search), {
      ignoreQueryPrefix: true,
    })
    this.loadWishlist(currentParams)
  },
  mounted() {
    this.actionProfileDetails()
  },
  methods: {
    ...mapActions({
      actionProfileDetails: ACTION_PROFILE_DETAILS,
    }),
    ...mapActionsWishlist({
      loadWishlist: ACTION_LOAD_WISHLIST,
    }),
  },
}
