import { FormHelper } from './classHelper/ClassFormHelper'
import {
  required,
  requiredIf,
  email,
  helpers,
  sameAs,
  numeric,
  maxLength,
  minValue,
  between,
} from 'vuelidate/lib/validators'
import wcs from '../libs/wcs/index'

const formJson = () => {
  return {
    1: {
      fields: [
        {
          field: new FormHelper(
            'login-input-email',
            'email',
            'email',
            'User email',
            'email',
            'invalid_email_message'
          ).getInput(),
          validate: {
            email: validationEmail(),
          },
        },
        {
          field: new FormHelper(
            'login-input-password',
            'password',
            'password',
            'User Password',
            'minMaxLength',
            'password_requirments'
          ).getInput(),
          validate: {
            password: validationPassword(),
          },
        },
      ],
    },
    2: {
      fields: [
        {
          field: new FormHelper(
            'join-input-email',
            'email',
            'email',
            'User email',
            'email',
            'invalid_email_message'
          ).getInput(),
          validate: {
            email: validationEmail(),
          },
        },

        {
          field: new FormHelper(
            'join-input-name',
            'text',
            'firstName',
            'User name',
            'alpha',
            'name_error'
          ).getInput(),
          validate: {
            firstName: validationName(),
          },
        },

        {
          field: new FormHelper(
            'join-input-surname',
            'text',
            'lastName',
            'User surname',
            'alpha',
            'surname_error'
          ).getInput(),
          validate: {
            lastName: validationLastName(),
          },
        },

        {
          field: new FormHelper(
            'join-input-password',
            'password',
            'password',
            'User Password',
            'minMaxLength',
            'password_requirments'
          ).getInput(),
          validate: {
            password: validationPassword(),
          },
        },

        {
          field: new FormHelper(
            'join-input-gender',
            'radio',
            'gender',
            'gender',
            '',
            ''
          ).getInputOptions([
            {
              key: 'female',
              value: 'F',
            },
            {
              key: 'male',
              value: 'M',
            },
            {
              key: 'no_to_say_option',
              value: '',
            },
          ]),
        },

        {
          field: new FormHelper(
            'join-input-birthdate',
            '',
            'birthdate',
            'User birthdate',
            '',
            '',
            false
          ).getSubfields([
            {
              id: 'join-input-birthdate-day',
              name: 'day',
              type: 'text',
              title: 'User birthdate day',
              error: 'date_error_day',
              min: '1',
              max: '31',
            },
            {
              id: 'join-input-birthdate-month',
              name: 'month',
              type: 'text',
              title: 'User birthdate month',
              error: 'date_error_month',
              min: '1',
              max: '12',
            },
            {
              id: 'join-input-birthdate-year',
              name: 'year',
              type: 'text',
              title: 'User birthdate year',
              error: 'date_error_year',
              min: '1900',
              max: '9999',
            },
          ]),
          validations: {
            day: validationDay(),
            month: validationMonth(),
            year: validationYear(),
          },
        },

        {
          field: new FormHelper(
            'join-input-phone',
            'tel',
            'phoneNumber',
            'User phone',
            'alphaNum',
            'not_phone_message',
            false
          ).getInput(),
          validate: {
            phoneNumber: validationPhone(),
          },
        },
        {
          field: new FormHelper(
            'join-loyalty-checkbox',
            'checkbox',
            'checkbox',
            'loyalty program subscribe',
            '',
            ''
          ).getInputOptions([
            {
              key: 'loyalty_subscribe_message',
              value: 'loyalty_subscribe_message',
            },
          ]),
        },
        {
          field: new FormHelper(
            'join-input-sms',
            'checkbox',
            'checkbox',
            'marketing sms',
            '',
            ''
          ).getInputOptions([
            {
              key: 'marketing_subscribe_sms',
              value: 'marketing_subscribe_sms',
            },
          ]),
        },
        {
          field: new FormHelper(
            'join-input-marketing',
            'checkbox',
            'checkbox',
            'marketing subscribe',
            '',
            ''
          ).getInputOptions([
            {
              key: 'marketing_subscribe_message',
              value: 'marketing_subscribe_message',
            },
          ]),
        },
      ],
      createAccount: true,
    },
    3: {
      fields: [
        {
          field: new FormHelper(
            'senderEmail',
            'email',
            'email',
            'User email',
            'email',
            'invalid_email_message'
          ).getInput(),
          validate: {
            email: validationEmail(),
          },
        },
      ],
    },
    4: {
      fields: [
        {
          field: new FormHelper(
            'update-profile-name',
            'text',
            'firstName',
            'User name',
            'alpha',
            'name_error'
          ).getInput(),
          validate: {
            firstName: validationName(),
          },
        },

        {
          field: new FormHelper(
            'update-profile-surname',
            'text',
            'lastName',
            'User surname',
            'alpha',
            'surname_error'
          ).getInput(),
          validate: {
            lastName: validationLastName(),
          },
        },

        {
          field: new FormHelper(
            'update-profile-gender',
            'radio',
            'gender',
            'gender',
            '',
            ''
          ).getInputOptions([
            {
              key: 'female',
              value: 'F',
            },
            {
              key: 'male',
              value: 'M',
            },
            {
              key: 'no_to_say_option',
              value: '',
            },
          ]),
        },

        {
          field: new FormHelper(
            'update-profile-birthdate',
            '',
            'birthdate',
            'User birthdate',
            '',
            '',
            false
          ).getSubfields([
            {
              id: 'update-profile-birthdate-day',
              name: 'day',
              type: 'text',
              title: 'User birthdate day',
              error: 'date_error_day',
              min: '1',
              max: '31',
            },
            {
              id: 'update-profile-birthdate-month',
              name: 'month',
              type: 'text',
              title: 'User birthdate month',
              error: 'date_error_month',
              min: '1',
              max: '12',
            },
            {
              id: 'update-profile-birthdate-year',
              name: 'year',
              type: 'text',
              title: 'User birthdate year',
              error: 'date_error_year',
              min: '1900',
              max: '9999',
            },
          ]),
          validations: {
            day: validationDay(),
            month: validationMonth(),
            year: validationYear(),
          },
        },

        {
          field: new FormHelper(
            'update-profile-phone',
            'tel',
            'phoneNumber',
            'User phone',
            'alphaNum',
            'not_phone_message',
            false
          ).getInput(),
          validate: {
            phoneNumber: validationPhone(),
          },
        },
      ],
    },
    5: {
      fields: [
        {
          field: new FormHelper(
            'newEmail',
            'email',
            'newEmail',
            'User email',
            'email',
            'invalid_email_message'
          ).getInput(),
          validate: {
            newEmail: validationEmail(),
          },
        },
      ],
    },
    6: {
      fields: [
        {
          field: new FormHelper(
            'currentPassword',
            'password',
            'currentPassword',
            'User Password',
            'minMaxLength',
            'password_requirments'
          ).getInput(),
          validate: {
            currentPassword: validationPassword(),
          },
        },
        {
          field: new FormHelper(
            'newPassword',
            'password',
            'newPassword',
            'User Password',
            'minMaxLength',
            'password_requirments'
          ).getInput(),
          validate: {
            newPassword: validationPassword(),
          },
        },
        {
          field: new FormHelper(
            'confirmPassword',
            'password',
            'confirmPassword',
            'User Password',
            'minMaxLength',
            'invalid_password_match_message'
          ).getInput(),
          validate: {
            confirmPassword: validationConfirmPassword(),
          },
        },
      ],
    },
    8: {
      fields: [
        {
          field: new FormHelper(
            'address-user-email',
            'email',
            'email',
            'User email',
            'email',
            'invalid_email_message'
          ).getInput(),
          validate: {
            email: validationEmail(),
          },
        },
        {
          field: new FormHelper(
            'address-user-name',
            'text',
            'firstName',
            'User name',
            'alpha',
            'name_error'
          ).getInput(),
          validate: {
            firstName: validationName(),
          },
        },
        {
          field: new FormHelper(
            'address-user-surname',
            'text',
            'lastName',
            'User surname',
            'alpha',
            'surname_error'
          ).getInput(),
          validate: {
            lastName: validationLastName(),
          },
        },
        {
          field: new FormHelper(
            'address-user-phone',
            'tel',
            'phoneNumber',
            'User phone',
            'alphaNum',
            'not_phone_message',
            false
          ).getInput(),
          validate: {
            phoneNumber: validationAddressPhone(),
          },
        },
        {
          field: new FormHelper(
            'street-address',
            'text',
            'streetAddress',
            'Street address',
            'alpha',
            'address_error'
          ).getInput(),
          validate: {
            streetAddress: validationAddress(),
          },
        },
        {
          field: new FormHelper(
            'address-apartment',
            'text',
            'addressApartment',
            'C/O, Apartment, Floor (optional)',
            'alpha',
            'apartment_error'
          ).getInput(),
          validate: {
            addressApartment: validationApartment(),
          },
        },
        {
          field: new FormHelper(
            'zip-code',
            'text',
            'zipCode',
            'Zip code',
            'alphaNum',
            'not_zip_message',
            false
          ).getInput(),
          validate: {
            zipCode: validationZipCode(),
          },
        },
        {
          field: new FormHelper(
            'address-city',
            'text',
            'city',
            'City (or APO/FPO)',
            'alpha',
            'city_error'
          ).getInput(),
          validate: {
            city: validationAddress(),
          },
        },
        {
          field: new FormHelper(
            'address-state',
            'text',
            'state',
            'State (or AA/AE/AP)',
            'alpha',
            'state_error'
          ).getInput(),
          validate: {
            state: validationState(),
          },
        },
        {
          field: new FormHelper(
            'address-country',
            'text',
            'country',
            'Country',
            'alpha',
            'country_error'
          ).getInput(),
          validate: {
            country: validationName(),
          },
        },
        {
          field: new FormHelper(
            'country-id',
            'hidden',
            'countryId',
            'countryId',
            '',
            ''
          ).getInput(),
          validate: {
            countryId: '',
          },
        },
        {
          field: new FormHelper(
            'default-delivery-address',
            'checkbox',
            'checkbox',
            'marketing subscribe',
            '',
            ''
          ).getInputOptions([
            {
              key: 'default_delivery_address',
              value: 'default_delivery_address',
            },
          ]),
        },
        {
          field: new FormHelper(
            'default-billing-address',
            'checkbox',
            'checkbox',
            'marketing subscribe',
            '',
            ''
          ).getInputOptions([
            {
              key: 'default_billing_address',
              value: 'default_billing_address',
            },
          ]),
        },
      ],
    },
    9: {
      fields: [
        {
          field: new FormHelper(
            'newPassword',
            'password',
            'newPassword',
            'User Password',
            'minMaxLength',
            'password_requirments'
          ).getInput(),
          validate: {
            newPassword: validationPassword(),
          },
        },
        {
          field: new FormHelper(
            'confirmPassword',
            'password',
            'confirmPassword',
            'User Password',
            'minMaxLength',
            'invalid_password_match_message'
          ).getInput(),
          validate: {
            confirmPassword: validationConfirmPassword(),
          },
        },
      ],
    },
    10: {
      fields: [
        {
          field: new FormHelper(
            'address-user-name',
            'text',
            'firstName',
            'User name',
            'alpha',
            'name_error'
          ).getInput(),
          validate: {
            firstName: validationName(),
          },
        },
        {
          field: new FormHelper(
            'address-user-surname',
            'text',
            'lastName',
            'User surname',
            'alpha',
            'surname_error'
          ).getInput(),
          validate: {
            lastName: validationLastName(),
          },
        },
        {
          field: new FormHelper(
            'address-user-email',
            'email',
            'email',
            'User email',
            'email',
            'invalid_email_message'
          ).getInput(),
          validate: {
            email: validationEmail(),
          },
        },
        {
          field: new FormHelper(
            'address-user-phone',
            'tel',
            'phoneNumber',
            'User phone',
            'alphaNum',
            'not_phone_message',
            false
          ).getInput(),
          validate: {
            phoneNumber: validationAddressPhone(),
          },
        },
        {
          field: new FormHelper(
            'zip-code',
            'text',
            'zipCode',
            'Zip code',
            'alphaNum',
            'not_zip_message',
            false
          ).getInput(),
          validate: {
            zipCode: validationZipCode(),
          },
        },
        {
          field: new FormHelper(
            'address-state',
            'text',
            'state',
            'State (or AA/AE/AP)',
            'alpha',
            'state_error'
          ).getInput(),
          validate: {
            state: validationState(),
          },
        },
        {
          field: new FormHelper(
            'address-city',
            'text',
            'city',
            'City (or APO/FPO)',
            'alpha',
            'city_error'
          ).getInput(),
          validate: {
            city: validationAddress(),
          },
        },
        {
          field: new FormHelper(
            'address-municipio',
            'text',
            'municipio',
            'Municipio',
            'alpha',
            'municipio_error'
          ).getInput(),
          validate: {
            municipio: validationAddress(),
          },
        },
        {
          field: new FormHelper(
            'address-colonia',
            'text',
            'colonia',
            'Colonia',
            'alpha',
            'colonia_error'
          ).getInput(),
          validate: {
            colonia: validationAddress(),
          },
        },
        {
          field: new FormHelper(
            'street-address',
            'text',
            'streetAddress',
            'Street address',
            'alpha',
            'address_error'
          ).getInput(),
          validate: {
            streetAddress: validationAddress(),
          },
        },
        {
          field: new FormHelper(
            'address-country',
            'text',
            'country',
            'Country',
            'alpha',
            'country_error'
          ).getInput(),
          validate: {
            country: validationName(),
          },
        },
        {
          field: new FormHelper(
            'country-id',
            'hidden',
            'countryId',
            'countryId',
            '',
            ''
          ).getInput(),
          validate: {
            countryId: '',
          },
        },
        {
          field: new FormHelper(
            'default-delivery-address',
            'checkbox',
            'checkbox',
            'marketing subscribe',
            '',
            ''
          ).getInputOptions([
            {
              key: 'default_delivery_address',
              value: 'default_delivery_address',
            },
          ]),
        },
        {
          field: new FormHelper(
            'default-billing-address',
            'checkbox',
            'checkbox',
            'marketing subscribe',
            '',
            ''
          ).getInputOptions([
            {
              key: 'default_billing_address',
              value: 'default_billing_address',
            },
          ]),
        },
      ],
    },
    11: {
      fields: [
        {
          field: new FormHelper(
            'address-user-email',
            'email',
            'email',
            'User email',
            'email',
            'invalid_email_message'
          ).getInput(),
          validate: {
            email: validationEmail(),
          },
        },
        {
          field: new FormHelper(
            'address-user-name',
            'text',
            'firstName',
            'User name',
            'alpha',
            'name_error'
          ).getInput(),
          validate: {
            firstName: validationName(),
          },
        },
        {
          field: new FormHelper(
            'address-user-surname',
            'text',
            'lastName',
            'User surname',
            'alpha',
            'surname_error'
          ).getInput(),
          validate: {
            lastName: validationLastName(),
          },
        },
        {
          field: new FormHelper(
            'address-billingCode',
            'billingCode',
            'billingCode',
            'User billingCode',
            'billingCode',
            'invalid_billingCode_message'
          ).getInput(),
          validate: {
            billingCode: validationCpf(),
          },
        },
        {
          field: new FormHelper(
            'address-mobilePhone1Country',
            'mobilePhone1Country',
            'mobilePhone1Country',
            'User mobilePhone1Country',
            'mobilePhone1Country',
            'invalid_mobilePhone1Country'
          ).getInput(),
          validate: {
            mobilePhone1Country: validationEstadual(),
          },
        },
        {
          field: new FormHelper(
            'cnpj-isento',
            'checkbox',
            'checkbox',
            'isento',
            '',
            ''
          ).getInputOptions([
            {
              key: 'phone2Type',
              value: 'phone2Type',
            },
          ]),
        },
        {
          field: new FormHelper(
            'address-user-phone',
            'tel',
            'phoneNumber',
            'User phone',
            'alphaNum',
            'not_phone_message',
            false
          ).getInput(),
          validate: {
            phoneNumber: validationAddressPhone(),
          },
        },
        {
          field: new FormHelper(
            'zip-code',
            'text',
            'zipCode',
            'Zip code',
            'alphaNum',
            'not_zip_message',
            false
          ).getInput(),
          validate: {
            zipCode: validationZipCodeBr(),
          },
        },
        {
          field: new FormHelper(
            'street-address',
            'text',
            'streetAddress',
            'Street address',
            'length',
            'address_error'
          ).getInput(),
          validate: {
            streetAddress: validationStreetAddress(),
          },
        },
        {
          field: new FormHelper(
            'fax2-address',
            'text',
            'fax2',
            'Number address',
            'alpha',
            'fax2_error'
          ).getInput(),
          validate: {
            fax2: numberAddress(),
          },
        },
        {
          field: new FormHelper(
            'municipio-address',
            'text',
            'municipio',
            'Complemento address',
            'alpha',
            'municipio_error'
          ).getInput(),
          validate: {
            municipio: complementoAddress(),
          },
        },
        {
          field: new FormHelper(
            'address-colonia',
            'text',
            'colonia',
            'Bairro',
            'length',
            'colonia_error'
          ).getInput(),
          validate: {
            colonia: validationColonia(),
          },
        },
        {
          field: new FormHelper(
            'address-state',
            'text',
            'state',
            'State (or AA/AE/AP)',
            'alpha',
            'state_error'
          ).getInput(),
          validate: {
            state: validationState(),
          },
        },
        {
          field: new FormHelper(
            'address-city',
            'text',
            'city',
            'City (or APO/FPO)',
            'alpha',
            'city_error'
          ).getInput(),
          validate: {
            city: validationAddress(),
          },
        },
        {
          field: new FormHelper(
            'address-country',
            'text',
            'country',
            'Country',
            'alpha',
            'country_error'
          ).getInput(),
          validate: {
            country: validationName(),
          },
        },
        {
          field: new FormHelper(
            'country-id',
            'hidden',
            'countryId',
            'countryId',
            '',
            ''
          ).getInput(),
          validate: {
            countryId: '',
          },
        },
        {
          field: new FormHelper(
            'default-delivery-address',
            'checkbox',
            'checkbox',
            'marketing subscribe',
            '',
            ''
          ).getInputOptions([
            {
              key: 'default_delivery_address',
              value: 'default_delivery_address',
            },
          ]),
        },
        {
          field: new FormHelper(
            'default-billing-address',
            'checkbox',
            'checkbox',
            'marketing subscribe',
            '',
            ''
          ).getInputOptions([
            {
              key: 'default_billing_address',
              value: 'default_billing_address',
            },
          ]),
        },
      ],
    },
  }
}

const { globalCountry } = wcs.getStoreConfig()
let zipCodeRegex = null
let phoneRegex = null
let zipCodeAutocomplete = null

switch (globalCountry.toLowerCase()) {
  case 'de':
  case 'nl': {
    phoneRegex = helpers.regex('phoneRegex', /^[0-9]{9,15}$/i)
    break
  }
  case 'es': {
    phoneRegex = helpers.regex('phoneRegex', /^[0-9]{9,11}$/i)
    break
  }
  case 'fr': {
    phoneRegex = helpers.regex('phoneRegex', /^[0-9]{9,11}$/i)
    break
  }
  case 'br': {
    phoneRegex = helpers.regex('phoneRegex', /^[0-9]{10,11}$/i)
    break
  }
  case 'gb': {
    phoneRegex = helpers.regex('phoneRegex', /^[0-9]{11,11}$/i)
    break
  }
  case 'au':
  case 'nz': {
    phoneRegex = helpers.regex(
      'phoneRegex',
      /^(?=[+]64)([+]64[234679][0-9]{5,9}$)|(?=[+]61)([+]61[23478][0-9]{5,8}$)/i
    )
    break
  }
  case 'ca': {
    phoneRegex = helpers.regex('phoneRegex', /^[0-9]{10,10}$/i)
    break
  }
  default: {
    phoneRegex = helpers.regex('phoneRegex', /^[0-9]{10,10}$/i)
    break
  }
}

const alphaAndSpace = helpers.regex('alphaAndSpace', /^[A-Za-zÀ-ÖØ-öø-ÿ ]*$/i)
const alphaSpaceAndDot = helpers.regex('alphaSpaceAndDot', /^[A-Za-zÀ-ÖØ-öø-ÿ0-9-.,'´()/ ]*$/i)
const billingCode = helpers.regex(
  'billingCode',
  /^(\d{3})\.?(\d{3})\.?(\d{3})\-?(\d{2}$)$|^(\d{2})\.?(\d{3})\.?(\d{3})\/?([0-1]{4})\-?(\d{2})$/
)

const billingCodeCPF = helpers.regex('billingCodeCPF', /^(\d{3})\.?(\d{3})\.?(\d{3})\-?(\d{2}$)/)

//const passwordRegex = helpers.regex('passwordRegex', /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/)
//const alphaNumCheck = helpers.regex('alphaNum', /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/\./0-9]*$/g)

const validationEmail = () => {
  return { required, email }
}

const validationState = () => {
  return { required }
}

const validationName = () => {
  return { required, alphaAndSpace }
}

const validationLastName = () => {
  return { required, alphaAndSpace }
}

const validationApartment = () => {
  return { alphaSpaceAndDot }
}

const validationBirthdate = () => {
  return {
    minValueAge: value => {
      if (value == '') return true
      const inputDate = Date.parse(value)
      const maxDate = new Date()
      maxDate.setFullYear(maxDate.getFullYear() - 16)
      return inputDate < Date.parse(maxDate.toISOString())
    },
  }
}

const validationZipCode = () => {
  return { required, zipCodeRegex }
}
const validationZipCodeBr = () => {
  return { required, zipCodeRegex, zipCodeAutocomplete }
}
const validationPhone = () => {
  return { numeric }
}

const validationAddressPhone = () => {
  return { required, phoneRegex }
}

const validationPassword = () => {
  return {
    required,
  }
}

const validationConfirmPassword = () => {
  return {
    required,
    sameAs: sameAs('newPassword'),
  }
}

const validationDay = () => {
  return {
    required: requiredIf(form => form.month !== '' || form.year !== ''),
    maxLength: maxLength(2),
    amount: (value, form) => {
      if (form.month !== '' || form.year !== '') {
        const date = new Date(form.year, form.month, 0)
        const maxDay = date.getDate()
        if (parseInt(value) <= parseInt(maxDay)) {
          return true
        }
        return false
      }
      return true //if month/year aren't populated, amount is not checked
    },
    minValue: (value, form) => {
      if (form.month !== '' || form.year !== '') {
        return value >= 1
      }
      return true //if month/year aren't populated, minValue is not checked
    },
  }
}

const validationMonth = () => {
  return {
    required: requiredIf(form => form.day !== '' || form.year !== ''),
    maxLength: maxLength(2),
    between: between(1, 12),
  }
}

const validationYear = () => {
  const currentYear = (new Date().getFullYear() - 16).toString()
  return {
    required: requiredIf(
      form =>
        ['fr', 'de', 'es', 'gb'].includes(globalCountry.toLowerCase()) &&
        (form.day !== '' || form.month !== '')
    ),
    maxLength: maxLength(4),
    between: between(1900, currentYear),
  }
}

const validationAddress = () => {
  return { required, alphaSpaceAndDot }
}

const validationStreetAddress = () => {
  return { required, alphaSpaceAndDot, maxLength: maxLength(35) }
}

const validationColonia = () => {
  return { required, alphaSpaceAndDot, maxLength: maxLength(60) }
}

const validationCpf = () => {
  return { required, billingCode }
}

const numberAddress = () => {
  return { required, maxLength: maxLength(5) }
}

const complementoAddress = () => {
  return { maxLength: maxLength(10) }
}

const validationEstadual = () => {
  return {
    required,
    maxLength: maxLength(35),
  }
}

export default formJson
