
import { createNamespacedHelpers } from 'vuex'
import { restoreLoyaltySubscription } from '../../libs/wcs/my-account'
import { ACTION_LOYALTY_STATUS } from '../../store/actions/my-account'
const { mapActions, mapGetters: mapGettersMyAccount } = createNamespacedHelpers('myAccount')

export default {
  name: 'sgh-loyalty-banner',
  props: {
    points: {
      type: String,
      default: '0',
    },
    isLogoPresent: {
      type: Boolean,
      default: false,
    },
    isEmptyCartPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      registrationModalOpen: false,
      openModal: false,
      isJoin: true,
      configuratorPopup: {
        size: 'md',
        posclose: 'right-top',
      },
      loading: false,
    }
  },
  computed: {
    ...mapGettersMyAccount(['myAccountDetails', 'profileDetails']),
    isLogged() {
      return this.myAccountDetails.loggedIn
    },
    isLoyaltyMember() {
      return this.profileDetails?.objectPerson?.isLoyaltyActive
    },
    isLoyaltyMemberOnHold() {
      return this.profileDetails?.objectPerson?.loyaltyStatus == 'SUSPENDED'
    },
    isNotVerifiedMember() {
      return (
        this.profileDetails?.objectPerson?.personalInfo?.registrationApprovalStatus === 'Approved'
      )
    },
    title() {
      let titleKey = 'guest'

      if (this.isLoyaltyMember) titleKey = this.isNotVerifiedMember ? 'not_verified' : 'member'
      else if (this.isLoyaltyMemberOnHold) titleKey = 'on_hold'

      return this.$t(`loyalty_banner_title_${titleKey}`, [this.points])
    },
    description() {
      let descriptionKey = 'logged'

      if (!this.isLogged) descriptionKey = 'guest'
      else if (this.isLoyaltyMember) {
        if (this.isEmptyCartPage) descriptionKey = 'member_empty_cart'
        else if (this.isNotVerifiedMember) descriptionKey = 'not_verified'
        else descriptionKey = 'member'
      } else if (this.isLoyaltyMemberOnHold) descriptionKey = 'on_hold'

      return this.$t(`loyalty_banner_description_${descriptionKey}`, [this.points])
    },
    smsOptinEnabled() {
      return sessionStorage.getItem('smsOptinEnabled') || 'false'
    },
  },
  mounted() {
    this.closeModal()
    window.loyaltyBanner = this
    this.$root.$on('goLoginExclusive', () => (this.isJoin = false))
    document.body.classList.add('loyalty-present')
  },
  methods: {
    ...mapActions({
      updateLoyaltyStatus: ACTION_LOYALTY_STATUS,
    }),
    overlayBenefitsOpen() {
      this.openModal = true
      window.document.body.style.overflow = 'hidden'
      this.loyaltyTrackingEvent()
    },
    closeModal() {
      this.openModal = false
      window.document.body.style.overflow = 'auto'
    },
    openRegistrationLayer() {
      this.registrationModalOpen = true
      this.isJoin = true
      document.querySelector('body').classList.add('noscroll')

   
    },
    loyaltyTrackingEvent() {
      this.$ma.trackEvent({
        event: 'Impression',
        value: {
          Page_Section2: 'BenefitPanel',
        },
      })
    },
    checkRefreshPage() {
      if (this.isEmptyCartPage) {
        window.location.reload()
      }
    },
    restoreSubscription() {
      this.loading = true
      restoreLoyaltySubscription(this.profileDetails.objectPerson.loyaltyJwtToken)
        .then(() => {
          const user = { email: this.profileDetails.objectPerson.registeredEmail }
          this.updateLoyaltyStatus(user)
        })
        .finally(() => (this.loading = false))
    },
  },
}
