import { actions } from '../actions/order'
import { getters } from '../getters/order'
import { mutations } from '../mutations/order'

export const order = {
  namespaced: true,
  state: {
    orderDetails: {
      error: null,
      errorId: null,
      errorReturnLabel: null,
      errorDownloadInoviceFile: false,
      invoiceFile: null,
    },
    returnDetails: {
      step: 1,
      itemsChecked: [],
      zipcodeAutocomlete: [],
      data: {
        orderId: null,
        returnItems: [],
        addressId: null,
        isFromStatusOrderPage: true,
      },
      newOrderId: null,
      loading: false,
      error: null,
      contact: {
        state: null,
        addressType: 'ShippingAndBilling',
        email1: null,
        phone1: null,
        country: window.globalCountry || 'US',
        city: null,
        addressLine: null,
        nickName: null,
        zipCode: null,
        primary: 'false',
        firstName: null,
        lastName: null,
      },
    },
  },
  actions,
  getters,
  mutations,
}
