import Vue from 'vue'
import VueRouter from 'vue-router'
import { getCurrentCountry } from '../scripts/libs/utils/currentCountry'
import myAccount from './../scripts/components/my-account/my-account-base.vue'
import login from './../scripts/components/my-account/my-account-login.vue'
import thankyou from './../scripts/components/my-account/my-account-thanks.vue'
import overview from './../scripts/components/my-account/my-account-overview.vue'
import wishlist from './../scripts/components/my-account/my-account-wishlist.vue'
import appointments from './../scripts/components/my-account/my-account-book-appointment.vue'
import emailConfermed from './../scripts/components/my-account/my-account-email-confermed.vue'
import emailFailed from './../scripts/components/my-account/my-account-email-failed.vue'
import profile from './../scripts/components/my-account/my-account-profile.vue'
import loyaltyProgram from './../scripts/components/my-account/my-account-loyalty-program.vue'
import orders from './../scripts/components/my-account/my-account-orders.vue'
import returns from './../scripts/components/my-account/my-account-returns.vue'
import forgotPassword from './../scripts/components/my-account/my-account-forgot-password.vue'
import resetPassword from './../scripts/components/my-account/my-account-reset-password.vue'
import orderDetail from './../scripts/components/my-account/components/orders/order-details.vue'
import returnDetail from './../scripts/components/my-account/components/returns/return-details.vue'
import { myAccount as myAccountModule } from '../scripts/store/modules/my-account'
import { getUserType } from '../scripts/libs/wcs/my-account'
import wcs from '../scripts/libs/wcs/index'

let userLogged
const baseUrl =
  window.location.hostname === 'localhost'
    ? '/sghstatichtml/playground/my-account/'
    : !window.location.pathname.includes('webapp/wcs/stores/servlet')
    ? '/' + getCurrentCountry()
    : '/'

Vue.use(VueRouter)
let routes = []
routes = [
  {
    path: '/myaccount',
    name: 'myaccount',
    component: myAccount,
  },
  {
    path: '/myaccount/login',
    name: 'login',
    component: login,
  },
  {
    path: '/myaccount/registration',
    name: 'registration',
    component: login,
  },
  {
    path: '/myaccount/thankyou',
    name: 'thankyou',
    component: thankyou,
  },
  {
    path: '/myaccount/overview',
    name: 'overview',
    component: overview,
  },
  {
    path: '/myaccount/your-loyalty-program',
    name: 'your-loyalty-program',
    component: loyaltyProgram,
  },
  {
    path: '/myaccount/details',
    name: 'details',
    component: profile,
  },
  {
    path: '/myaccount/orderHistory',
    name: 'orderHistory',
    component: orders,
  },
  {
    path: '/myaccount/orderHistory?:id',
    name: 'orderDetail',
    component: orderDetail,
  },
  {
    path: '/myaccount/returnHistory',
    name: 'returnHistory',
    component: returns,
  },
  {
    path: '/myaccount/returnHistory?:id',
    name: 'returnDetail',
    component: returnDetail,
  },
  {
    path: '/myaccount/forgotPassword',
    name: 'forgotPassword',
    component: forgotPassword,
  },
  {
    path: '/myaccount/resetPassword',
    name: 'resetPassword',
    component: resetPassword,
  },

  {
    path: '/wishlist',
    name: 'wishlist',
    component: wishlist,
  },
  {
    path: '/myaccount/get-an-appointment',
    name: 'get-an-appointment',
    component: appointments,
  },
  {
    path: '/myaccount/registration-confirmed',
    name: 'registration-confirmed',
    component: emailConfermed,
  },
  {
    path: '/myaccount/registration-failed',
    name: 'registration-failed',
    component: emailFailed,
  },
]

const router = new VueRouter({
  base: baseUrl,
  hashbang: false,
  mode: window.location.hostname === 'localhost' ? 'hash' : 'history',
  routes: routes,
  scrollBehavior(to, from) {
    if (to != from) {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach(async (to, from, next) => {
  typeof userLogged == 'undefined'
    ? await getUserType().then(userType => {
        userLogged = userType === 'R'
        myAccountModule.mutations.UPDATE_LOGON_MYACCOUNT(myAccountModule.state, userLogged)
      })
    : (userLogged = wcs.isUserLogged())

  const myAccountPath = [
    'myaccount',
    'details',
    'your-loyalty-program',
    'overview',
    'orderHistory',
    'returnHistory',
    'orderDetail',
    'returnDetail',
    'get-an-appointment',
  ]
  const myAccountLoginPath = ['myaccount', 'login', 'registration']
  let value = false

  if (window.location.hostname !== 'localhost' && myAccountPath.includes(to.name) && !userLogged) {
    next({ name: 'login', query: { redirect: to.fullPath } })
  } else if (
    window.location.hostname !== 'localhost' &&
    myAccountLoginPath.includes(to.name) &&
    userLogged
  ) {
    next({ name: 'overview' })
  } else if (to.name === 'orderHistory' && Object.keys(to.query).length) {
    next({ name: 'orderDetail', params: { id: Object.keys(to.query)[0] } })
  } else if (to.name === 'returnHistory' && Object.keys(to.query).length) {
    next({ name: 'returnDetail', params: { id: Object.keys(to.query)[0] } })
  } else if (
    !value &&
    from.name === 'details' &&
    myAccountModule?.state?.profileDetails?.objectPerson?.personalInfo
      ?.registrationApprovalStatus === 'Approved'
  ) {
    value = true
    next({ name: 'details' })
  } else {
    next()
  }
})

export default router
