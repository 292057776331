import { render, staticRenderFns } from "./return-details.vue?vue&type=template&id=11fb5a17"
import script from "./return-details.vue?vue&type=script&lang=js"
export * from "./return-details.vue?vue&type=script&lang=js"
import style0 from "./return-details.vue?vue&type=style&index=0&id=11fb5a17&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports