
import isEmpty from 'lodash/isEmpty'
import { createNamespacedHelpers } from 'vuex'
import { EUROPEAN_COUNTRIES } from '../../libs/utils/const'
import {
  ACTION_UPDATE_BREADCRUMB,
  ACTION_LOAD_LOYALTY_SCRIPT,
  ACTION_LOYALTY_STATUS,
} from '../../store/actions/my-account'
import { getCurrentCountry, getCurrentLocale } from '../../libs/utils/currentCountry'
const { mapActions, mapGetters: mapGettersMyAccount } = createNamespacedHelpers('myAccount')

export default {
  name: 'sgh-my-account-loyalty-program',
  data() {
    return {
      urlJS: sessionStorage.getItem('loyaltyJsPath'),
      urlCSS: sessionStorage.getItem('loyaltyCssPath'),
      scriptLoaded: false,
      cssLoaded: false,
      openPopup: false,
      configuratorPopup: {
        size: 'xs',
        posclose: 'right-top',
        margin: 'mt-2.4rem mdr:my-0',
      },
    }
  },
  computed: {
    ...mapGettersMyAccount(['profileDetails', 'loyaltyScriptLoaded']),
    isLoyaltyActive() {
      return (
        (this.profileDetails.objectPerson.loyaltyStatus == 'ACTIVE' ||
          this.profileDetails.objectPerson.loyaltyStatus == 'SUSPENDED') &&
        this.loyaltyScriptLoaded
      )
    },
    isLoyaltyOnHold() {
      return (
        this.profileDetails.objectPerson.loyaltyStatus == 'SUSPENDED' && this.loyaltyScriptLoaded
      )
    },
    isNotLoyaltyMember() {
      return isEmpty(this.profileDetails.objectPerson.loyaltyStatus)
    },
    getJwtToken() {
      return !this.isNotLoyaltyMember && this.profileDetails?.objectPerson?.loyaltyJwtToken
        ? this.profileDetails.objectPerson.loyaltyJwtToken
        : this.profileDetails?.objectPerson?.loyaltyNotMemberJwtToken
        ? this.profileDetails.objectPerson.loyaltyNotMemberJwtToken
        : ''
    },
    locale() {
      const currentLocale = getCurrentLocale()
      return currentLocale === 'en-UK' ? 'en-GB' : currentLocale
    },
    region() {
      const country = getCurrentCountry()
      if (EUROPEAN_COUNTRIES.includes(country)) {
        return 'EU'
      } else if (country === 'us') {
        return 'US'
      }
      return undefined
    },
  },
  beforeMount() {
    this.updateBreadcrumb([
      { label: this.$t('ma_menu_overview'), to: 'overview' },
      { label: this.$t('ma_menu_your_loyalty_program') },
    ])
  },
  mounted() {
    this.loadExternalLibrariesCondition()
    this.profileUnsubscribeListener()
    this.profileRestoreListener()
    this.$nextTick(function() {
      this.trackingLoyaltySection()
    })
    document.onreadystatechange = () => {
      if (document.readyState == 'complete') {
        this.trackingLoyaltySection()
      }
    }
    this.$root.$on('subscribeSuccess', () => this.loadExternalLibrariesCondition())
  },
  methods: {
    ...mapActions({
      updateBreadcrumb: ACTION_UPDATE_BREADCRUMB,
      updateLoyaltyScript: ACTION_LOAD_LOYALTY_SCRIPT,
      updateLoyaltyStatus: ACTION_LOYALTY_STATUS,
    }),
    loadExternalLibraries(urlJS, urlCSS) {
      const loyaltyScript = document.createElement('script')
      const loyaltyCss = document.createElement('link')
      loyaltyCss.setAttribute('href', urlCSS)
      loyaltyCss.setAttribute('rel', 'stylesheet')
      //loyaltyCss.setAttribute('type', 'text/css')
      loyaltyScript.setAttribute('src', urlJS)
      loyaltyScript.setAttribute('type', 'module')
      document.head.appendChild(loyaltyCss)
      document.body.appendChild(loyaltyScript)

      loyaltyScript.addEventListener('load', () => {
        this.scriptLoaded = true
        if (this.cssLoaded) {
          this.updateLoyaltyScript(true)
        }
      })

      loyaltyCss.addEventListener('load', () => {
        this.cssLoaded = true
        if (this.scriptLoaded) {
          this.updateLoyaltyScript(true)
        }
      })
    },
    profileUnsubscribeListener() {
      document.addEventListener('lxlwidgetprofileunsubscribe', event => {
        if (event.target.id === 'loyalty-program') {
          const user = { email: this.profileDetails.objectPerson.registeredEmail }
          this.updateLoyaltyStatus(user)
          this.openPopup = true
          if (this.$refs.refPop) this.$refs.refPop.isOpen = true
        }
      })
    },
    profileRestoreListener() {
      document.addEventListener('lxlwidgetprofilerestore', event => {
        if (event.target.id === 'loyalty-program') {
          const user = { email: this.profileDetails.objectPerson.registeredEmail }
          this.updateLoyaltyStatus(user)
        }
      })
    },
    trackingLoyaltySection() {
      this.$ma.trackEventSPA({
        Page_Type: 'LoyaltyProgram',
        Page_Section1: 'Account',
        Page_Section2: '',
      })
    },
    loadExternalLibrariesCondition() {
      if (!this.loyaltyScriptLoaded) {
        this.loadExternalLibraries(this.urlJS, this.urlCSS)
      }
    },
  },
}
