export const ACTION_TOGGLE_FAVOURITE_STORE = 'ACTION_TOGGLE_FAVOURITE_STORE'
export const ACTION_SET_DEFAULT_FAVOURITE_STORE = 'ACTION_SET_DEFAULT_FAVOURITE_STORE'
export const ACTION_REMOVE_MYACCOUNT_FAVOURITE_STORE = 'ACTION_REMOVE_MYACCOUNT_FAVOURITE_STORE'

import { toggleFavouriteStores, setDefaultStore } from '../../libs/wcs/my-account'
import {
  UPDATE_TOGGLE_FAVOURITE_STORE,
  UPDATE_DEFAULT_FAVOURITE_STORE,
  UPDATE_REMOVE_MYACCOUNT_FAVOURITE_STORE,
} from '../mutations/shared'

export const actionsFavouriteStore = {
  [ACTION_TOGGLE_FAVOURITE_STORE]: async function({ commit, state }, payload) {
    const data = await toggleFavouriteStores(payload)
    if (!data.error) {
      if (!payload.storeId) {
        payload.storeId = data.storeLocationId
      }
      commit(UPDATE_TOGGLE_FAVOURITE_STORE, payload)
    }
  },
  [ACTION_SET_DEFAULT_FAVOURITE_STORE]: async function({ commit, state }, store) {
    const data = await setDefaultStore(store.endpoints.default)

    if (!data.error) {
      const defaultStoreIndex = state.favouriteStores.findIndex(element => element.id == store.id)
      commit(UPDATE_DEFAULT_FAVOURITE_STORE, defaultStoreIndex)
    }
  },
  [ACTION_REMOVE_MYACCOUNT_FAVOURITE_STORE]: async function({ commit, state }, store) {
    const payload = {
      endpoint: store.endpoints.remove,
      favourite: false,
    }
    const data = await toggleFavouriteStores(payload)
    if (!data.error) {
      commit(UPDATE_REMOVE_MYACCOUNT_FAVOURITE_STORE, store.id)
    }
  },
}
