
import { createNamespacedHelpers } from 'vuex'
import { loadSectionTranslations } from '../../../../i18n-setup'
import { getCurrentCountry } from '../../../../libs/utils/currentCountry'
import {
  ACTION_LOAD_ORDER_RETURN_HISTORY,
  ACTION_UPDATE_BREADCRUMB,
  ACTION_GET_ORDER_LABEL,
  ACTION_GET_DOWNLOAD_INVOICE_FILE,
} from '../../../../../scripts/store/actions/my-account'
const { mapActions, mapGetters: mapGettersMyAccount } = createNamespacedHelpers('myAccount')
import find from 'lodash/find'
import join from 'lodash/join'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'

export default {
  name: 'sgh-my-account-return-details',
  data() {
    return {
      openLabelPopup: false,
      loading: false,
      isDownloadedInvoice: false,
    }
  },

  computed: {
    destinationId() {
      return this.$route.params.id
    },
    ...mapGettersMyAccount(['orders', 'profileDetails']),

    currentOrder() {
      return find(this.orders.orderReturnHistory, { orderId: this.$route.params.id })
    },

    orderItemsGroup() {
      let itemsGroup = groupBy(this.currentOrder.orderItems, 'trackingUrl')
      return itemsGroup
    },

    showOrder() {
      return this.currentOrder
    },

    isCurrentOrderReturned() {
      return this.currentOrder.orderType == 'STD' && !isEmpty(this.currentOrder.rmaIds) //todo && orderStatusLetter == ?
    },

    rmaIds() {
      return join(this.currentOrder.rmaIds, '/')
    },

    deliveryAddress() {
      return `${this.currentOrder.firstName} ${this.currentOrder.lastName}, ${Object.values(
        this.currentOrder.returnAddress
      ).toString()}`
    },

    getReturnUrl() {
      return `/${getCurrentCountry()}/status`
    },
    isEgiftCard() {
      let firstItem = this.currentOrder.orderItems[0]
      return firstItem && firstItem.giftCard && firstItem.carrier == 'EGift'
    },
    isGiftCard() {
      let firstItems = this.currentOrder.orderItems[0]
      return firstItems.giftCard
    },
    orderStatus() {
      return this.currentOrder.orderStatusNormalized
    },
    isBorisCountry() {
      const currentCountry = getCurrentCountry()
      return currentCountry === 'us' || currentCountry === 'ca-en' || currentCountry === 'ca-fr'
    },
    isBr() {
      return getCurrentCountry() === 'br'
    },
    isBoris() {
      return this.currentOrder.isBoris ? this.currentOrder.isBoris : false
    },
    addressStore() {
      const physicalStore = this.currentOrder.physicalStore ? this.currentOrder.physicalStore : null
      return physicalStore ? physicalStore : null
    },
    showReturnDetails() {
      if (!this.$route.params.id) {
        return true
      }
      if (this.orders.isLoading) {
        return false
      } else if (!isEmpty(this.orders.orderReturnHistory)) {
        let order = find(this.orders.orderReturnHistory, {
          orderId: this.$route.params.id,
        })
        return !isEmpty(order)
      }
      return false
    },
    isLoyaltyMember() {
      return (
        sessionStorage.getItem('isLoyaltyProgramEnabled') === 'true' &&
        this.profileDetails?.objectPerson?.isLoyaltyActive
      )
    },
  },
  beforeMount() {
    loadSectionTranslations('my-account-new').then(() => {
      this.updateBreadcrumb([
        { label: this.$t('ma_return_history'), to: '/myaccount/returnHistory' },
        { label: `${this.$t('ma_return')} ${this.$route.params.id}` },
      ])
    })
  },
  mounted() {
    this.$root.$on('closeLabelPopup', () => (this.openLabelPopup = false))
    //check if orders already in state or we need to load them
    if (isEmpty(this.orders.orderReturnHistory)) {
      let emailUser = this.emailRegistered()
      this.loadOrderItem(emailUser).then(() => {
        if (this.isBr && !this.orders.orderReturnLabel)
          this.loadSrcLabel(this.currentOrder.returnLabelURL)
      })
    } else if (this.isBr && !this.orders.orderReturnLabel)
      this.loadSrcLabel(this.currentOrder.returnLabelURL)
  },
  methods: {
    ...mapActions({
      loadOrderItem: ACTION_LOAD_ORDER_RETURN_HISTORY,
      updateBreadcrumb: ACTION_UPDATE_BREADCRUMB,
      loadSrcLabel: ACTION_GET_ORDER_LABEL,
      downloadInvoiceFile: ACTION_GET_DOWNLOAD_INVOICE_FILE,
    }),
    emailRegistered() {
      return this.profileDetails &&
        this.profileDetails.objectPerson &&
        this.profileDetails.objectPerson.registeredEmail
        ? this.profileDetails.objectPerson.registeredEmail
        : ''
    },
    clickPrintLabel() {
      this.loading = true
      this.loadSrcLabel(this.currentOrder.returnLabelURL).then(() => {
        this.loading = false
        this.openLabelPopup = true
      })
    },
    downloadInvoice() {
      this.loading = true
      this.downloadInvoiceFile(this.currentOrder.orderId).then(() => {
        if (!this.orders.errorDownloadInoviceFile) {
          var docUrl = document.querySelector('.downloadInvoiceButton')
          docUrl.href = this.orders.invoiceFile
          docUrl.setAttribute('download', 'file.zip')
          this.isDownloadedInvoice = true
          docUrl.click()
        }
        this.loading = false
      })
    },
  },
}
