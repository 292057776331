export const ACTION_OPEN_MODAL = 'ACTION_OPEN_MODAL'
export const ACTION_OPEN_MODAL_SIZE_GUIDE = 'ACTION_OPEN_MODAL_SIZE_GUIDE'
export const ACTION_UPDATE_TARGET_BACK = 'ACTION_UPDATE_TARGET_BACK'
import {
  UPDATE_OPEN_MODAL,
  UPDATE_OPEN_MODAL_SIZE_GUIDE,
  UPDATE_TARGET_BACK,
} from '../mutations/sidebar'
const bodySelector = document.querySelector('body')

export const actions = {
  [ACTION_OPEN_MODAL]: function({ state, commit }, payload) {
    commit(UPDATE_OPEN_MODAL, payload)
    const overflowValue = state.isModalOpen ? 'hidden' : 'auto'
    bodySelector.style.overflowY = overflowValue
  },
  [ACTION_OPEN_MODAL_SIZE_GUIDE]: function({ state, commit }, payload) {
    commit(UPDATE_OPEN_MODAL_SIZE_GUIDE, payload)
    const overflowValue = state.isSizeGuideOpen ? 'hidden' : 'auto'
    bodySelector.style.overflowY = overflowValue
  },
  [ACTION_UPDATE_TARGET_BACK]: function({ commit }, payload) {
    commit(UPDATE_TARGET_BACK, payload)
  },
}
