
import { createNamespacedHelpers } from 'vuex'
import { loadSectionTranslations } from '../../../../i18n-setup'
import { redirectTo404 } from '../../../../libs/utils/url'
import {
  ACTION_LOAD_ORDER_RETURN_HISTORY,
  ACTION_UPDATE_BREADCRUMB,
  ACTION_GET_ORDER_LABEL,
  ACTION_GET_DOWNLOAD_INVOICE_FILE,
} from '../../../../../scripts/store/actions/my-account'

const { mapActions, mapGetters: mapGettersMyAccount } = createNamespacedHelpers('myAccount')

import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import { getCurrentCountry } from '../../../../libs/utils/currentCountry'

export default {
  name: 'sgh-my-account-order-details',
  data() {
    return {
      openLabelPopup: false,
      loading: false,
      isDownloadedInvoice: false,
    }
  },
  computed: {
    destinationId() {
      return this.$route.params.id
    },
    ...mapGettersMyAccount(['orders', 'profileDetails']),

    currentOrder() {
      return find(this.orders.orderReturnHistory, { orderId: this.$route.params.id })
    },

    orderItemsGroups() {
      let itemsGroup = this.groupByMultipleValues(
        this.filterOrderItems(this.currentOrder.orderItems),
        function(item) {
          return [item.remixed, item.trackingUrl, item.estimatedDeliveryDate]
        }
      )
      return itemsGroup
    },

    isMultipleShipping() {
      return Object.keys(this.orderItemsGroups).length > 1 ? true : false
    },

    showOrder() {
      return this.currentOrder
    },
    isCurrentOrderReturned() {
      return this.currentOrder.orderType == 'STD' && !isEmpty(this.currentOrder.rmaIds) //todo && orderStatusLetter == ?
    },
    rmaIds() {
      return this.currentOrder.rmaIds
    },

    orderStatus() {
      return this.currentOrder.orderStatusNormalized
    },

    deliveryMethod() {
      return this.currentOrder.orderItems[0] ? this.currentOrder.orderItems[0].shipMode : ''
    },
    getReturnUrl() {
      return `/${getCurrentCountry()}/return?email=${this.currentOrder.billingEmail}&orderId=${
        this.currentOrder.orderId
      }`
    },
    isEgiftCard() {
      let firstItem = this.currentOrder.orderItems[0]
      return firstItem && firstItem.giftCard && firstItem.carrier == 'EGift'
    },
    isGiftCard() {
      let firstItem = this.currentOrder.orderItems[0]
      return firstItem.giftCard
    },
    isReturnable() {
      return !!find(this.currentOrder.orderItems, 'returnable')
    },
    isBr() {
      return getCurrentCountry() === 'br'
    },
    isUs() {
      return getCurrentCountry() === 'us'
    },
    isMx() {
      return getCurrentCountry() === 'mx'
    },
    isCollectedCNC() {
      return this.orderStatus == 'collected' && this.currentOrder.omniOrderType == 'CNC'
    },
    showOrderDetails() {
      if (!this.$route.params.id) {
        return true
      }
      if (this.orders.isLoading) {
        return false
      } else if (!isEmpty(this.orders.orderReturnHistory)) {
        let order = find(this.orders.orderReturnHistory, {
          orderId: this.$route.params.id,
        })
        return !isEmpty(order)
      }
      return false
    },
    isLoyaltyMember() {
      return (
        sessionStorage.getItem('isLoyaltyProgramEnabled') === 'true' &&
        this.profileDetails?.objectPerson?.isLoyaltyActive
      )
    },
    isProtectionDetailsAvailable() {
      const protectionItem = this.currentOrder.orderItems.some(item => !!item.protectionDetails)
      return protectionItem
    },
  },
  beforeMount() {
    loadSectionTranslations('my-account-new').then(() => {
      this.updateBreadcrumb([
        { label: this.$t('ma_order_history'), to: '/myaccount/orderHistory' },
        { label: `${this.$t('ma_order')} ${this.$route.params.id}` },
      ])
    })
  },
  mounted() {
    this.$root.$on('closeLabelPopup', () => (this.openLabelPopup = false))
    //check if orders already in state or we need to load them
    if (isEmpty(this.orders.orderReturnHistory)) {
      let emailUser = this.emailRegistered()
      this.loadOrderItem(emailUser)
    }
  },

  methods: {
    ...mapActions({
      loadOrderItem: ACTION_LOAD_ORDER_RETURN_HISTORY,
      updateBreadcrumb: ACTION_UPDATE_BREADCRUMB,
      loadSrcLabel: ACTION_GET_ORDER_LABEL,
      downloadInvoiceFile: ACTION_GET_DOWNLOAD_INVOICE_FILE,
    }),
    filterOrderItems(items) {
      return (
        items &&
        items.filter(item => {
          return !item.lensRx
        })
      )
    },
    groupByMultipleValues(array, f) {
      var groups = {}
      array.forEach(function(o) {
        var group = JSON.stringify(f(o))
        groups[group] = groups[group] || []
        groups[group].push(o)
      })
      return Object.keys(groups).map(function(group) {
        return groups[group]
      })
    },

    emailRegistered() {
      return this.profileDetails &&
        this.profileDetails.objectPerson &&
        this.profileDetails.objectPerson.registeredEmail
        ? this.profileDetails.objectPerson.registeredEmail
        : ''
    },

    clickPrintLabel() {
      this.loading = true
      this.loadSrcLabel(this.currentOrder.returnLabelURL).then(() => {
        this.loading = false
        this.openLabelPopup = true
      })
    },

    downloadInvoice() {
      this.loading = true
      this.downloadInvoiceFile(this.currentOrder.orderId).then(() => {
        if (!this.orders.errorDownloadInoviceFile) {
          var docUrl = document.querySelector('.downloadInvoiceButton')
          docUrl.href = this.orders.invoiceFile
          docUrl.setAttribute('download', 'file.zip')
          this.isDownloadedInvoice = true
          docUrl.click()
        }
        this.loading = false
      })
    },
    openPop() {
      this.$refs.refPop.isOpen = true
    },
    getTotalInsurance() {
      const totalInsurance = this.currentOrder?.totalInsurance ?? 0
      return totalInsurance
    },
  },
}
