
export default {
  name: 'resetPassword',
  data() {
    return {}
  },
  computed: {
    isMobile() {
      return this.$mq == 'tablet' || this.$mq == 'tabletLandscape'
    },
  },
}
