import { getters } from '../getters/sidebar'
import { actions } from '../actions/sidebar'
import { mutations } from '../mutations/sidebar'

export const sidebar = {
  namespaced: true,
  state: {
    isModalOpen: false,
    isSizeGuideOpen: false,
    componentName: null,
    targetBack: '',
  },
  actions,
  getters,
  mutations,
}
