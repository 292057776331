import { actions } from '../actions/frame-advisor'
import { getters } from '../getters/frame-advisor'
import { mutations } from '../mutations/frame-advisor'

export const frameAdvisor = {
  namespaced: true,
  state: {
    fa: {
      currentPage: 1,
      pagination: 18,
      products: [],
      showLanding: true,
      UPCS: [],
      usedUPCS: [],
      userProfileData: {},
      visitedPdpUrl: '',
    },
  },
  actions,
  getters,
  mutations,
}
